import React, { Component } from "react";
import "./App.css";
import AppManager from "./AppManager";
import ComponentManager from "./ComponentManager";

export default class Column extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadOnly: this.props.isReadOnly,
      currentColumnID: this.props.currentColumnID,
      data: this.props.data,
      width: this.props.width,
      marginRight: this.props.marginRight,
      marginBottom: this.props.marginBottom,
      textSize: this.props.textSize,
      textColor: this.props.textColor,
      textAlignment: this.props.textAlignment,
      imageShape: this.props.imageShape,
      isImageHidden: this.props.isImageHidden,
      isHorizontalColumn: this.props.isHorizontalColumn,
    };
  }

  componentWillReceiveProps(nextProp) {
    // if (this.state.currentColumnID === this.state.data.id) {
    this.setState({
      isReadOnly: nextProp.isReadOnly,
      data: nextProp.data,
      width: nextProp.width,
      marginRight: nextProp.marginRight,
      marginBottom: nextProp.marginBottom,
      textSize: nextProp.textSize,
      textColor: nextProp.textColor,
      textAlignment: nextProp.textAlignment,
      imageShape: nextProp.imageShape,
      isImageHidden: nextProp.isImageHidden,
      isHorizontalColumn: nextProp.isHorizontalColumn,
    });
    // }
  }

  getHeaderFontSize() {
    if (this.state.textSize === "small") {
      return ComponentManager.getFontSize(3);
    } else if (this.state.textSize === "medium") {
      return ComponentManager.getFontSize(5);
    } else if (this.state.textSize === "large") {
      return ComponentManager.getFontSize(6);
    }
    return ComponentManager.getFontSize(6);
  }
  getTextFontSize() {
    if (this.state.textSize === "small") {
      return ComponentManager.getFontSize(1);
    } else if (this.state.textSize === "medium") {
      return ComponentManager.getFontSize(2);
    } else if (this.state.textSize === "large") {
      return ComponentManager.getFontSize(5);
    }
    return ComponentManager.getFontSize(5);
  }

  render() {
    return (
      <div
        className={
          !this.state.isReadOnly ||
          (this.state.isReadOnly &&
            this.state.data.url &&
            this.state.data.url !== "")
            ? "HandCursor"
            : "MouseCursor"
        }
        style={{
          width: "" + this.state.width + "%",
          display: "flex",
          flexDirection: this.state.isHorizontalColumn ? "row" : "column",
          marginRight: "" + this.state.marginRight + "%",
          marginTop: "2.2vw",
        }}
        onClick={this.checkToOpenWebURL}
      >
        {!this.state.isImageHidden && (
          <img
            src={
              this.state.data.image
                ? this.state.data.image
                : require("./Assets/ic_thumbnail_default.png")
            }
            alt=""
            style={{
              width: this.state.isHorizontalColumn ? "50%" : "100%",
              height: "100%",
              borderRadius:
                this.state.imageShape === "circular"
                  ? "50%"
                  : this.state.imageShape === "cornerRadius"
                  ? "20%"
                  : "0%",
              objectFit: "cover",
            }}
          />
        )}
        <div
          className="ColumnDiv"
          style={{ paddingLeft: this.state.isHorizontalColumn ? "1vw" : 0 }}
        >
          <text
            className={this.getHeaderFontSize()}
            style={{
              color: this.state.textColor,
              opacity: 0.9,
              fontWeight: "bold",
              marginTop: this.state.isHorizontalColumn ? "0vw" : "1vw",
              textAlign: this.state.textAlignment,
              width: "100%",
            }}
          >
            {this.state.data.heading}
          </text>
          <text
            className={this.getTextFontSize()}
            style={{
              color: this.state.textColor,
              opacity: 0.6,
              marginTop: "0.3vw",
              textAlign: this.state.textAlignment,
              width: "100%",
              whiteSpace: "pre-wrap",
            }}
          >
            {this.state.data.text}
          </text>
        </div>
      </div>
    );
  }

  checkToOpenWebURL = () => {
    if (
      this.state.isReadOnly &&
      this.state.data.url &&
      this.state.data.url !== ""
    ) {
      AppManager.openWebURL(this.state.data.url);
    }
  };
}
