import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import PageManager from "./PageManager";
import PageApiManager from "./PageApiManager";
import { COLOR_BLUE } from "./Constants";
import AppManager from "./AppManager";

export default class CreatePagePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "+ Create page",
      subTitle:
        "Great! You are very close to create your new page on website. Enter the page details below and proceed.",
      createButtonTitle: "Create my page",
      pageName: "",
      apiCalled: false,
      waitMsg: "Please wait while we create your page....",
      pageCreated: false,
    };
  }

  pageNameChanged = (e) => {
    if (!this.state.apiCalled) {
      this.setState({
        pageName: e.target.value,
      });
    }
  };

  render() {
    return (
      <div
        className="WindowPopup"
        style={{
          color: "black",
          paddingLeft: 30,
          paddingRight: 30,
          width: 450,
          height: "auto",
        }}
      >
        <div style={{ position: "absolute", right: 15, top: 8 }}>
          {ComponentManager.getButton("X", "gray", this.cancelTapped)}
        </div>
        <div style={{ height: 5 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              fontSize: 16,
            }}
          >
            <text style={{ fontSize: 25, fontWeight: "bold" }}>
              {this.state.title}
            </text>
            <br />
            <text>{this.state.subTitle}</text>
            <br />
            {!this.state.pageCreated && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 15,
                  marginBottom: 20,
                }}
              >
                <input
                  className="TextField"
                  style={{ marginBottom: 0, marginLeft: 5, marginRight: 5 }}
                  placeholder="your page name"
                  type="text"
                  value={this.state.pageName}
                  onChange={this.pageNameChanged}
                />
              </div>
            )}
            <div style={{ height: 10 }} />
            <text style={{ fontSize: 12, color: "gray" }}>
              • Use default template
            </text>
            <text style={{ fontSize: 12, color: "gray", marginTop: 5 }}>
              • Create blank page
            </text>
            <br />
          </div>

          <div
            style={{
              width: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={require("./Assets/ic_createDomain.png")}
              alt=""
              style={{ width: "90%" }}
            />
          </div>
        </div>
        {!this.state.apiCalled && (
          <div className="row" style={{ justifyContent: "center" }}>
            {ComponentManager.getButton(
              this.state.createButtonTitle,
              COLOR_BLUE,
              this.createPageTapped
            )}
          </div>
        )}
        {this.state.apiCalled && (
          <text style={{ fontSize: 13, color: COLOR_BLUE, fontWeight: "700" }}>
            {this.state.waitMsg}
          </text>
        )}
        <div style={{ height: 15 }} />
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onCancel();
  };
  createPageTapped = () => {
    if (this.state.pageName === "") {
      alert("Please enter page name to proceed!");
      return;
    }
    this.setState({
      apiCalled: true,
    });

    PageApiManager.createPage(this.state.pageName, [], this);
  };

  pageCreatedSuccess(pageID, pageName, jsonData) {
    PageManager.addPage(pageID, pageName, jsonData);
    var msg =
      "Your page has been created successfully. Now you can add a content to this page and make it available to end-users.";

    this.props.onPageCreate(pageID, this.state.pageName, jsonData);

    this.setState({
      waitMsg: "Page created!",
      title: "Congratulations!",
      subTitle: msg,
      pageCreated: true,
    });

    AppManager.dataNotSaved();

    this.cancelTapped();
  }
  pageCreateFailed() {
    this.setState({
      apiCalled: false,
    });
  }
}
