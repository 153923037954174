import React, { Component } from "react";
import "../Common.css";
import "../App.css";
import HeaderMenu from "../HeaderMenu";
import MenuManager from "../MenuManager";
import CartManager from "./Managers/CartManager";
import { COLOR_BLUE } from "../Constants";
import ComponentManager from "../ComponentManager";

export default class OrderConfirmationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0,
    };
  }

  render() {
    return (
      <div
        className="column parent-height"
        style={{ backgroundColor: "white" }}
      >
        {/* <HeaderMenu
          menuSettings={MenuManager.getMenuSettings()}
          websiteTitle="Deskera"
          //   websiteLogo={WebsiteManager.getWebsiteLogo()}
          pages={[]}
          isReadOnly={false}
          isUserLoggedIn={this.state.isUserLoggedIn}
          onBackgroundColorChanged={this.backgroundColorChanged}
          onFullscreenTapped={this.fullscreenTapped}
          onLoginTapped={this.loginTapped}
          onSaveTapped={this.saveTapped}
          onPublishTapped={this.publishTapped}
          onNewPageTapped={this.newPageTapped}
          onPageButtonTapped={this.pageButtonTapped}
          onEditTapped={this.editWebsiteTapped}
        /> */}
        <div
          className="column parent-width"
          style={{
            padding: "3vw",
            maxWidth: 1200,
            alignSelf: "center",
            flex: 1,
            overflowY: "hidden",
          }}
        >
          <text className=" fw-b" style={{ fontSize: 22 }}>
            Order Confirmation
          </text>
          <div
            className="column parent-width justify-content-center align-items-center"
            style={{
              marginTop: 20,
              flex: 1,
              overflowY: "hidden",
            }}
          >
            {this.orderView()}
          </div>
        </div>
      </div>
    );
  }
  checkOut = () => {
    CartManager.emptyCart();
    this.resetData();
  };

  orderView() {
    return (
      <div className="column parent-size align-items-center">
        <img
          src={require("../Assets/ic_emptyBag2.png")}
          alt=""
          style={{ width: 200, marginTop: 50 }}
        />
        <text className="fw-b" style={{ fontSize: 16, marginTop: 8 }}>
          Your order has been placed successfully!
        </text>
        <text
          style={{
            fontSize: 14,
            color: "gray",
            marginTop: 8,
            marginBottom: 28,
          }}
        >
          {"Order number : " + CartManager.getOrderNumber()}
        </text>
        {ComponentManager.getButton("Continue Shopping", COLOR_BLUE, () => {
          // this.props.history.push("/products");
          this.props.onContinueShopping();
        })}
      </div>
    );
  }
}
