import ApiConstants from "../../ApiConstants";
import ApiManager from "../../ApiManager";
import StoreManager from "../Managers/StoreManager";

/*
CALLBACKS -
1. storeReceiveSuccess(data)
2. storeReceiveFailed()
*/

export default class StoreApiClient {
  static getStoreInfo(presenter) {
    fetch(
      ApiConstants.URL.BASE_CONSTANT +
        ApiConstants.URL.STORE_API +
        "?tenantId=" +
        ApiConstants.TENANT_ID,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          presenter.storeDataReceived(null);
        } else {
          presenter.storeDataReceived(data);
        }
      })
      .catch((error) => {
        // alert("Internal server error occured. Try again later.");
        presenter.storeDataReceived(null);
      });
  }
}
