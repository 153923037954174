import React, { Component } from "react";
import DKSectionHeader from "./DKSectionHeader";
import DKLabel from "./DKLabel";
import DKSpaceH from "./DKSpaceH.js";
import DKButton from "./DKButton";

/*
PROPS
    - className
    - title
    - message
    - icon
    - buttons (Array of DKButtons)
*/

export default class DKAlert extends Component {
  render() {
    return (
      <div
        className="transparent-background"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      >
        <div
          className={
            "popup-window column border-s border-radius-s " +
            this.props.className
          }
          style={{ width: 300, padding: 15 }}
        >
          <div className="row">
            <DKSectionHeader
              className="text-align-left parent-width"
              text={this.props.title}
            />
          </div>
          <DKLabel
            className="text-align-left parent-width"
            style={{ marginTop: 8, marginBottom: 12 }}
            text={this.props.message}
          />

          <div className="row justify-content-end flex-wrap mt-l">
            {this.renderAllButtons()}
          </div>
        </div>
      </div>
    );
  }
  renderAllButtons() {
    let arr = [];
    let buttons = this.props.buttons
      ? this.props.buttons
      : [<DKButton title="Ok" />];
    for (let i = 0; i < buttons.length; i++) {
      arr.push(buttons[i]);
      arr.push(<DKSpaceH value={10} />);
    }
    return arr;
  }
}
