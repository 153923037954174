import React, { Component } from "react";

// import "../App.css";
/* 
PROPS
    - className
    - text
*/

export default class DKLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      text: nextProp.text,
    });
  }

  render() {
    return (
      <div
        className={" " + this.props.className}
        style={{
          ...{ textAlign: "left", fontSize: 13, wordWrap: "break-word" },
          ...this.props.style,
        }}
      >
        {this.state.text}
      </div>
    );
  }
}
