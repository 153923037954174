export function getVW(value) {
  return value / 14.3 + "vw"; //PIXEL_TO_VW
}

export function getCapitalized(name) {
  let str = name;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function isStringEmpty(str) {
  return str.replace(/\s/g, "") === "";
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function getDivById(id) {
  var div = document.getElementById(id);
  return div;
}
export function getDivTopById(id) {
  var div = getDivById(id);
  return div.offsetTop;
}
export function getDivBottomById(id) {
  var div = getDivById(id);
  return div.offsetTop + div.offsetHeight;
}
export function getDivLeftById(id) {
  var div = getDivById(id);
  return div.offsetLeft;
}
export function getDivRightById(id) {
  var div = getDivById(id);
  return div.offsetLeft + div.offsetWidth;
}

export function getMouseX(e) {
  return e.pageX;
}
export function getMouseY(e) {
  return e.pageY;
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export default class DKUtility {
  static getValueInK(value) {
    const kValue = "" + (value / 1000).toFixed(0) + "k";
    return kValue;
  }
  static encodeString(json) {
    return btoa(json);
  }

  static decodeString(base64String) {
    return JSON.parse(atob(base64String));
  }

  static isDevice() {
    if (window.screen.width <= 1242) {
      return true;
    }
    return false;
  }

  static toCurrencyFormat(number) {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  static openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  static isValidNumber(number) {
    return number.match(/^[0-9]+$/) === null;
  }
  static isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static setPersistentValue(value, key) {
    localStorage.setItem(key, value);
  }

  static getPersistentValue(key) {
    return localStorage.getItem(key);
  }

  static getCurrencySymbol(currencyCode) {
    if (
      currencyCode === "USD" ||
      currencyCode === "SGD" ||
      currencyCode === "AUD"
    ) {
      return "$";
    } else if (currencyCode === "EUR") {
      return "€";
    } else if (currencyCode === "INR") {
      return "₹";
    } else if (currencyCode === "IDR") {
      return "Rp";
    }
    return currencyCode;
  }
}
