import React, { Component } from "react";
import "../Common.css";
import "../App.css";
import HeaderMenu from "../HeaderMenu";
import MenuManager from "../MenuManager";
import ProductManager from "./Managers/ProductManager";
import ProductThumbnail from "./ProductThumbnail";
import ProductPopup from "./ProductPopup";
import ComponentManager from "../ComponentManager";
import DKAlert from "./Components/DKAlert";
import DKButton from "./Components/DKButton";
import { COLOR_BLUE } from "../Constants";
import ProductApiClient from "./API/ProductApiClient";
import DKLoading from "./Components/DKLoading";
import AppManager from "../AppManager";
import ApiConstants from "../ApiConstants";

export default class ProductsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: null,
      needProductPopup: false,
      currentProductData: null,
      currentProductIndex: null,
      needAlert: false,
    };

    setTimeout(() => {
      this.getInitialProducts();
    }, 100);
  }

  ////////////////
  ////////////////

  getInitialProducts() {
    if (ProductManager.getAllProducts() === null) {
      ProductApiClient.getAllProducts(this);
    } else {
      this.setProducts();
    }
  }

  productsReceiveSuccess(data) {
    ProductManager.setAllProducts(data);
    this.setProducts();
  }
  productsReceiveFailed() {
    ProductManager.setAllProducts([]);
    this.setProducts();
  }

  setProducts() {
    this.setState({
      products: ProductManager.getAllProducts(),
    });
  }

  ////////////////
  ////////////////

  render() {
    return (
      <div
        className="column parent-height"
        style={{ backgroundColor: "white" }}
      >
        {/* <HeaderMenu
          menuSettings={MenuManager.getMenuSettings()}
          websiteTitle="Deskera"
          //   websiteLogo={WebsiteManager.getWebsiteLogo()}
          pages={[]}
          isReadOnly={false}
          isUserLoggedIn={this.state.isUserLoggedIn}
          onBackgroundColorChanged={this.backgroundColorChanged}
          onFullscreenTapped={this.fullscreenTapped}
          onLoginTapped={this.loginTapped}
          onSaveTapped={this.saveTapped}
          onPublishTapped={this.publishTapped}
          onNewPageTapped={this.newPageTapped}
          onPageButtonTapped={this.pageButtonTapped}
          onEditTapped={this.editWebsiteTapped}
        /> */}
        <div
          className="column parent-width"
          style={{ padding: "3vw", maxWidth: 1200, alignSelf: "center" }}
        >
          <div className="row justify-content-between" style={{ width: "95%" }}>
            <div className="column">
              <text className=" fw-b" style={{ fontSize: 22 }}>
                {"All Products (" +
                  (this.state.products === null ||
                  this.state.products === undefined
                    ? 0
                    : this.state.products.length) +
                  " items)"}
              </text>
              {this.state.products && this.state.products.length > 0 && (
                <text
                  className="text-align-left text-gray"
                  style={{ marginTop: 8, fontSize: 14 }}
                >
                  A product page is simply the most important asset of an
                  eCommerce site. It's the landing page where a product is
                  displayed to the website visitors and the very start of the
                  cart and checkout functionalities.
                </text>
              )}
            </div>
            {ComponentManager.getButton(
              "My Cart",
              "black",
              () => {
                this.gotoToCart();
              },
              true,
              require("../Assets/menuIcons/ic_cart.png")
            )}
          </div>

          {this.state.products === null && <DKLoading />}
          {this.state.products &&
            this.state.products.length > 0 &&
            this.getProductsView(this.state.products)}
          {this.state.products &&
            this.state.products.length === 0 &&
            this.emptyView()}
        </div>
        {this.state.needProductPopup && this.getProductPopup()}
        {this.state.needAlert && this.getAlert()}
      </div>
    );
  }

  getProductsView(products) {
    return (
      <div className="row" style={{ flexWrap: "wrap", marginTop: 30 }}>
        {this.getAllProducts(products)}
      </div>
    );
  }

  getAllProducts(products) {
    let arr = [];
    for (let i = 0; i < products.length; i++) {
      arr.push(this.getProduct(i, products[i]));
    }
    return arr;
  }
  getProduct(index, data) {
    return (
      <div
        className="border-m column productThumbnail"
        style={{
          marginRight: "3%",
          marginBottom: "5%",
        }}
      >
        <ProductThumbnail
          index={index}
          data={data}
          onClick={this.imageTapped}
          onAddToCart={this.showAlert}
        />
      </div>
    );
  }

  imageTapped = (index, data) => {
    this.setState(
      {
        currentProductIndex: index,
        currentProductData: data,
      },
      () => {
        this.setState({
          needProductPopup: true,
        });
      }
    );
  };

  getProductPopup() {
    return (
      <ProductPopup
        index={this.state.currentProductIndex}
        data={this.state.currentProductData}
        onAddToCart={this.showAlert}
        onClose={() => {
          this.setState({
            needProductPopup: false,
          });
        }}
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  emptyView() {
    return (
      <div className="column parent-size align-items-center">
        <img
          src={require("../Assets/ic_emptyBag2.png")}
          alt=""
          style={{ width: 200, marginTop: 50 }}
        />
        <text className="fw-b" style={{ fontSize: 16, marginTop: 8 }}>
          No Products Found
        </text>
        {AppManager.isUserLoggedIn() && (
          <text
            style={{
              fontSize: 14,
              color: "gray",
              marginTop: 8,
              marginBottom: 28,
            }}
          >
            Please go to your store dashboard and add products to view here.
          </text>
        )}
        {AppManager.isUserLoggedIn() &&
          ComponentManager.getButton("Go to Dashboard", COLOR_BLUE, () => {
            AppManager.openWebURL(ApiConstants.STORE_DASHBOARD_URL, "_blank");
          })}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getAlert() {
    return (
      <DKAlert
        title="Added to Cart!"
        message="Item has been added to cart successfully."
        buttons={[
          <DKButton
            title="Go to Cart"
            className="bg-gray2"
            onClick={this.gotoToCart}
          />,
          <DKButton
            backgroundColor={COLOR_BLUE}
            color="white"
            title="Continue"
            onClick={this.hideAlert}
          />,
        ]}
      />
    );
  }

  showAlert = () => {
    this.setState({
      needAlert: true,
    });
  };
  hideAlert = () => {
    this.setState({
      needAlert: false,
    });
  };
  gotoToCart = () => {
    this.hideAlert();
    // this.props.history.push("/cart");
    this.props.onGotoCart();
  };
}
