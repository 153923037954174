export default class StoreManager {
  static storeData = null;
  static setStoreData(data) {
    StoreManager.storeData = data;
  }
  static getStoreData() {
    return this.storeData;
  }
  static getStoreCurrency() {
    if (StoreManager.storeData && StoreManager.storeData.id) {
      if (StoreManager.storeData.currency === "") {
        return "USD";
      }
      return StoreManager.storeData.currency;
    }

    return "USD";
  }
  static isStoreAvaialble() {
    return StoreManager.storeData && StoreManager.storeData.id;
  }

  static isStripeAccountSetup() {
    return (
      StoreManager.storeData &&
      StoreManager.storeData.hasStripeAccount &&
      StoreManager.storeData.stripeAccountLinkCreated
    );
  }
  static getStripeAccountID() {
    if (
      StoreManager.isStripeAccountSetup() &&
      StoreManager.storeData.stripeId
    ) {
      return StoreManager.storeData.stripeId;
    }
    return null;
  }
}
