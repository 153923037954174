export default class ProductManager {
  static products = null;

  static setAllProducts(data) {
    ProductManager.products = data;
  }
  static getAllProducts() {
    return ProductManager.products;
  }

  static getProductByIndex(index) {
    return ProductManager.products[index];
  }

  static getCopyOfProduct(data) {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      sellingPrice: data.sellingPrice,
      quantity: data.quantity,
      images: data.images,
    };
  }
}
