import PageApiManager from "./PageApiManager";
import StoreManager from "./eCommerce/Managers/StoreManager";

export default class PageManager {
  static presenter = null;
  static pageName = null;
  static currentPageIndex = 0;
  static currentPageID = 1;
  static currentPageTitle = "Default";

  static pages = [];

  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////

  static getComponentsDataForPage(pageID) {
    for (let i = 0; i < PageManager.pages.length; i++) {
      if (PageManager.pages[i].id === pageID) {
        if (
          PageManager.pages[i].content_json === "" ||
          PageManager.pages[i].content_json === null
        ) {
          return [];
        }
        return JSON.parse(PageManager.pages[i].content_json);
      }
    }
    return [];
  }
  static saveComponentsDataForPage(pageID, componentsData) {
    let data = PageManager.pages;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === pageID) {
        data[i].content_json = JSON.stringify(componentsData);
        return;
      }
    }
    // this.setAllPages(data);
    // if (PageManager.pageComponentsData.pageID === undefined) {
    //   PageManager.pageComponentsData[pageID] = componentsData;
    // } else {
    //   PageManager.pageComponentsData.pageID = componentsData;
    // }
  }

  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////

  static setAllPages(pagesDetails) {
    PageManager.pages = pagesDetails;
  }
  static getAllPages() {
    return PageManager.pages;
  }
  static addPage(id, title, jsonData) {
    PageManager.pages.push({
      id: id,
      title: title,
      content_json: jsonData,
      sequence_no: PageManager.pages.length,
    });
  }

  ////////////////////////////////////////

  static setCurrentPageIndex(pageIndex) {
    PageManager.currentPageIndex = pageIndex;
  }
  static getCurrentPageIndex() {
    return PageManager.currentPageIndex;
  }

  ////////////////////////////////////////

  static getCurrentPageID() {
    return PageManager.pages[PageManager.currentPageIndex].id;
  }

  ////////////////////////////////////////

  static getCurrentPageSequenceNo() {
    return PageManager.pages[PageManager.currentPageIndex].sequence_no;
  }

  ////////////////////////////////////////

  static getCurrentPageTitle() {
    return PageManager.pages[PageManager.currentPageIndex].title;
  }
  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////
}
