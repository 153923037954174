import React from "react";
import { MAX_WIDTH } from "./Constants";

export let componentType = {
  BANNER: "Image with text overlay",
  IMAGE_WITH_TEXT: "Image with text",
  PARAGRAPH: "Paragraph with title",
  ROW_WITH_COLUMNS: "Text column with image",
  COLLECTION: "Featured collection",
  COLUMN: "Column with image and text",
  TESTIMONIALS: "Testimonials",
  BLOG: "Blogs column",
  PHOTO_GALLERY: "Photo Gallery",
  NEWS_LETTER: "News Letter",
  BUTTON_SECTION: "Button",
};

export default class ComponentManager {
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  static getBannerData(id, type, sectionHeight) {
    return {
      id: id,
      type: type,
      backgroundColor: "#ffffff",
      sectionHeight: sectionHeight,
      textAlignment: "center",
      textSize: "large",
      textColor: "rgb(60, 60, 60)",
      image: null,
      imageAlignment: "middle",
      imageShape: "square",
      isImageHidden: false,
      heading: "Header text",
      text:
        "Use overlay text to give your customers insight into your brand. Select imagery and text that relates to your style and story.",
      paddingVertical: 0,
      paddingHorizontal: 0,
    };
  }

  ////////////////////////////////////////////////////////////////

  static getButtonSectionData(id, type) {
    return {
      id: id,
      type: type,
      backgroundColor: "#ffffff",
      textAlignment: "center",
      textSize: "large",
      textColor: "#ffffff",
      buttonColor: "#3773e1",
      imageShape: "circular",
      text: "Button Text",
      paddingVertical: 2,
      paddingHorizontal: 6,
      webURL: "",
    };
  }

  ////////////////////////////////////////////////////////////////

  static getImageWithTextSectionData(id, type) {
    return {
      id: id,
      type: type,
      backgroundColor: "#ffffff",
      sectionHeight: null,
      textSize: "medium",
      textColor: "#000000",
      textAlignment: "left",
      image: null,
      imageAlignment: "left",
      imageShape: "square",
      heading: "Section header",
      isImageHidden: false,
      text:
        "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
      paddingVertical: type === componentType.PARAGRAPH ? 4 : 2,
      paddingHorizontal: 6,
    };
  }

  static getRowWithColumnsSectionData(id, type) {
    return {
      id: id,
      type: type,
      backgroundColor: "#ffffff",
      heading: this.getRowWithColumnHeading(type),
      text: "",
      textSize: "medium",
      textColor: "#000000",
      textAlignment: "center",
      imageShape: "square",
      isImageHidden:
        type === componentType.TESTIMONIALS || type === componentType.BLOG,
      columns: ComponentManager.getAllColumnsData(
        ComponentManager.getNumberOfItemsInRowForType(type),
        type
      ),
      numberOfColumnsPerRow: ComponentManager.getNumberOfColumnsInRowForType(
        type
      ),
      isHorizontalColumn: false,
      isDistributeEqually: false,
      // !(
      //   type === componentType.COLLECTION ||
      //   type === componentType.PHOTO_GALLERY ||
      //   type === componentType.BLOG
      // )
      isSpaceEvenly: type === componentType.BLOG,
      paddingVertical: 4,
      paddingHorizontal: 6,
    };
  }

  static getNewLetterSectionData(id, type) {
    return {
      id: id,
      type: type,
      backgroundColor: "#ffffff",
      heading: "News Letter!",
      text: "Please subscribe to get updates from our shop",
      textSize: "medium",
      textColor: "#000000",
      textAlignment: "center",
      paddingVertical: 6,
      paddingHorizontal: 6,
    };
  }

  static getNumberOfColumnsInRowForType(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return 2;
      case componentType.BLOG:
        return 3;
      case componentType.PHOTO_GALLERY:
        return 4;
      case componentType.COLLECTION:
        return 4;
      case componentType.ROW_WITH_COLUMNS:
        return 4;
      default:
        return 4;
    }
  }

  static getNumberOfItemsInRowForType(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return 2;
      case componentType.BLOG:
        return 3;
      case componentType.PHOTO_GALLERY:
        return 8;
      case componentType.COLLECTION:
        return 8;
      case componentType.ROW_WITH_COLUMNS:
        return 4;
      default:
        return 0;
    }
  }

  static getAllColumnsData(noOfColumns, type) {
    var arr = [];
    for (let i = 1; i <= noOfColumns; i++) {
      arr.push(ComponentManager.getColumnData(i, type));
    }
    return arr;
  }

  static getRowWithColumnHeading(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return "Testimonials";
      case componentType.BLOG:
        return "Blogs";
      case componentType.PHOTO_GALLERY:
        return "Gallery Title";
      case componentType.COLLECTION:
        return "Collection Title";
      case componentType.ROW_WITH_COLUMNS:
        return "Column header";
      default:
        return "HEADER";
    }
  }

  static getColumnDataHeader(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return "Author's name";
      case componentType.BLOG:
        return "Add a title";
      case componentType.PHOTO_GALLERY:
        return "";
      case componentType.COLLECTION:
        return "Your product name";
      case componentType.ROW_WITH_COLUMNS:
        return "Add a title or tagline";
      default:
        return "TITLE";
    }
  }
  static getColumnDataText(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return "Add customer reviews and testimonials to showcase your store’s happy customers.";
      case componentType.BLOG:
        return "Share blog posts, products, or promotions with your customers.";
      case componentType.PHOTO_GALLERY:
        return "";
      case componentType.COLLECTION:
        return "S$ 19.99";
      case componentType.ROW_WITH_COLUMNS:
        return "Share blog posts, products, or promotions with your customers. Use this text to describe products, share details on availability and style, or as a space to display recent reviews or FAQs.";
      default:
        return "TEXT";
    }
  }
  static getColumnData(id, type) {
    return {
      id: id,
      type: componentType.COLUMN,
      image: null,
      heading: this.getColumnDataHeader(type),
      text: this.getColumnDataText(type),
      url: "",
    };
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  static getNewComponent(id, type) {
    var component = {};
    switch (type) {
      case componentType.BANNER:
        component = ComponentManager.getBannerData(
          id,
          componentType.BANNER,
          "auto"
        );
        break;
      case componentType.IMAGE_WITH_TEXT:
        component = ComponentManager.getImageWithTextSectionData(
          id,
          componentType.IMAGE_WITH_TEXT
        );
        break;
      case componentType.PARAGRAPH:
        component = ComponentManager.getImageWithTextSectionData(
          id,
          componentType.PARAGRAPH
        );
        break;
      case componentType.ROW_WITH_COLUMNS:
        component = ComponentManager.getRowWithColumnsSectionData(
          id,
          componentType.ROW_WITH_COLUMNS
        );
        break;
      case componentType.COLLECTION:
        component = ComponentManager.getRowWithColumnsSectionData(
          id,
          componentType.COLLECTION
        );
        break;
      case componentType.TESTIMONIALS:
        component = ComponentManager.getRowWithColumnsSectionData(
          id,
          componentType.TESTIMONIALS
        );
        break;
      case componentType.BLOG:
        component = ComponentManager.getRowWithColumnsSectionData(
          id,
          componentType.BLOG
        );
        break;
      case componentType.PHOTO_GALLERY:
        component = ComponentManager.getRowWithColumnsSectionData(
          id,
          componentType.PHOTO_GALLERY
        );
        break;
      case componentType.NEWS_LETTER:
        component = ComponentManager.getNewLetterSectionData(
          id,
          componentType.NEWS_LETTER
        );
        break;
      case componentType.BUTTON_SECTION:
        component = ComponentManager.getButtonSectionData(
          id,
          componentType.BUTTON_SECTION
        );
        break;
      default:
        component = {};
    }

    return component;
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getTransparentBackground(callback) {
    return (
      <div
        className="TransparentBackground"
        onClick={() => (callback ? callback() : {})}
      />
    );
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getButton(title, color, callback, isBordered = false, icon = null) {
    return (
      <div>
        <div
          className="row"
          style={{
            border: isBordered ? "solid" : "none",
            borderWidth: 1,
            borderColor: color,
            backgroundColor: isBordered ? "white" : color,
            color: isBordered ? color : "white",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 8,
            height: 30,
            fontWeight: isBordered ? null : "600",
            // marginLeft: 10,
            cursor: "pointer",
            outline: "none",
            fontSize: 13,
          }}
          onClick={callback}
        >
          {icon && (
            <img src={icon} alt="" style={{ height: 16, marginRight: 6 }} />
          )}
          <text style={{ whiteSpace: "nowrap" }}>{title}</text>
        </div>
      </div>
    );
  }
  static getImageButton(image, callback, leftMargin = 20, size = 18) {
    return (
      <div
        style={{
          height: size,
          // marginLeft: leftMargin,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          outline: "none",
        }}
        onClick={() => callback()}
      >
        <img src={image} alt="" style={{ height: "100%" }} />
      </div>
    );
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getFontSize(size) {
    return "Font" + size;
  }

  static getHeaderFontSize(sizeType) {
    if (sizeType === "small") {
      return ComponentManager.getFontSize(7);
    } else if (sizeType === "medium") {
      return ComponentManager.getFontSize(8);
    } else if (sizeType === "large") {
      return ComponentManager.getFontSize(10);
    } else if (sizeType === "extra-large") {
      return ComponentManager.getFontSize(11);
    }
    return ComponentManager.getFontSize(9);
  }
  static getTextFontSize(sizeType) {
    if (sizeType === "small") {
      return ComponentManager.getFontSize(3);
    } else if (sizeType === "medium") {
      return ComponentManager.getFontSize(4);
    } else if (sizeType === "large") {
      return ComponentManager.getFontSize(6);
    } else if (sizeType === "extra-large") {
      return ComponentManager.getFontSize(7);
    }
    return ComponentManager.getFontSize(5);
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getWideScreenSectionBackground(color) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -(window.screen.width - MAX_WIDTH) / 2,
          width: "100vw",
          height: "100%",
          backgroundColor: color,
        }}
      />
    );
  }
}
