import React, { Component } from "react";
import "./App.css";
import { componentType } from "./ComponentManager";
import { COLOR_BLUE } from "./Constants";

export default class ImageSettings extends Component {
  selectedColumnImageIndex = null;
  selectedImageSectionType = "M"; //C , M -> Main, C -> Column

  listPickerArrowStyle = {
    position: "absolute",
    bottom: 14,
    right: 13,
    width: 8,
    opacity: 0.8,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };

    // this.handleSelectedFile = this.handleSelectedFile.bind(this);
    // this.handleSelectedColumnFile = this.handleSelectedColumnFile.bind(this);
    this.headingChanged = this.headingChanged.bind(this);
    this.textChanged = this.textChanged.bind(this);
    this.webURLChanged = this.webURLChanged.bind(this);
    this.sectionHeightChanged = this.sectionHeightChanged.bind(this);
    this.textSizeChanged = this.textSizeChanged.bind(this);
    this.imageAlignmentChanged = this.imageAlignmentChanged.bind(this);
    this.imageShapeChanged = this.imageShapeChanged.bind(this);
    this.textAlignmentChanged = this.textAlignmentChanged.bind(this);
    this.textColorChanged = this.textColorChanged.bind(this);
    this.buttonColorChanged = this.buttonColorChanged.bind(this);
    this.backgroundColorChanged = this.backgroundColorChanged.bind(this);
    this.imageHiddenPropertyChanged = this.imageHiddenPropertyChanged.bind(
      this
    );
    this.horizontalColumnPropertyChanged = this.horizontalColumnPropertyChanged.bind(
      this
    );
    this.distributeEquallyPropertyChanged = this.distributeEquallyPropertyChanged.bind(
      this
    );
    this.spaceEvenlyPropertyChanged = this.spaceEvenlyPropertyChanged.bind(
      this
    );
    this.numberOfColumnsPerRowChanged = this.numberOfColumnsPerRowChanged.bind(
      this
    );
    this.columnHeadingChanged = this.columnHeadingChanged.bind(this);
    this.columnTextChanged = this.columnTextChanged.bind(this);
    this.columnURLChanged = this.columnURLChanged.bind(this);
    this.verticalPaddingIncreased = this.verticalPaddingIncreased.bind(this);
    this.verticalPaddingDecreased = this.verticalPaddingDecreased.bind(this);
    this.horizontalPaddingIncreased = this.horizontalPaddingIncreased.bind(
      this
    );
    this.horizontalPaddingDecreased = this.horizontalPaddingDecreased.bind(
      this
    );
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      data: nextProp.data,
    });

    if (nextProp.selectedImage) {
      this.imagePopupImageSelected(nextProp.selectedImage);
    }
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  verticalPaddingIncreased(event) {
    var data = this.state.data;
    if (data["paddingVertical"] + 2 > 30) {
      data["paddingVertical"] = 30;
    } else {
      data["paddingVertical"] = data["paddingVertical"] + 2;
    }
    this.dataChanged(data);
  }
  verticalPaddingDecreased(event) {
    var data = this.state.data;
    if (data["paddingVertical"] - 2 < 0) {
      data["paddingVertical"] = 0;
    } else {
      data["paddingVertical"] = data["paddingVertical"] - 2;
    }
    this.dataChanged(data);
  }
  horizontalPaddingIncreased(event) {
    var data = this.state.data;
    if (data["paddingHorizontal"] + 2 > 30) {
      data["paddingHorizontal"] = 30;
    } else {
      data["paddingHorizontal"] = data["paddingHorizontal"] + 2;
    }
    this.dataChanged(data);
  }
  horizontalPaddingDecreased(event) {
    var data = this.state.data;

    if (data["paddingHorizontal"] - 2 < 0) {
      data["paddingHorizontal"] = 0;
    } else {
      data["paddingHorizontal"] = data["paddingHorizontal"] - 2;
    }
    this.dataChanged(data);
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  headingChanged(event) {
    var data = this.state.data;
    data["heading"] = event.target.value;
    this.dataChanged(data);
  }
  textChanged(event) {
    var data = this.state.data;
    data["text"] = event.target.value;
    this.dataChanged(data);
  }

  webURLChanged(event) {
    var data = this.state.data;
    data["webURL"] = event.target.value;
    this.dataChanged(data);
  }

  sectionHeightChanged(event) {
    var data = this.state.data;
    data["sectionHeight"] = event.target.value;
    this.dataChanged(data);
  }
  textSizeChanged(event) {
    var data = this.state.data;
    data["textSize"] = event.target.value;
    this.dataChanged(data);
  }
  textAlignmentChanged(event) {
    var data = this.state.data;
    data["textAlignment"] = event.target.value;
    this.dataChanged(data);
  }
  imageAlignmentChanged(event) {
    var data = this.state.data;
    data["imageAlignment"] = event.target.value;
    this.dataChanged(data);
  }
  imageShapeChanged(event) {
    var data = this.state.data;
    data["imageShape"] = event.target.value;
    this.dataChanged(data);
  }
  numberOfColumnsPerRowChanged(event) {
    var data = this.state.data;
    data["numberOfColumnsPerRow"] = event.target.value;
    this.dataChanged(data);
  }
  textColorChanged(event) {
    var data = this.state.data;
    data["textColor"] = event.target.value;
    this.dataChanged(data);
  }
  buttonColorChanged(event) {
    var data = this.state.data;
    data["buttonColor"] = event.target.value;
    this.dataChanged(data);
  }
  backgroundColorChanged(event) {
    var data = this.state.data;
    data["backgroundColor"] = event.target.value;
    this.dataChanged(data);
  }
  imageHiddenPropertyChanged(event) {
    var data = this.state.data;
    data["isImageHidden"] = !data["isImageHidden"];
    this.dataChanged(data);
  }
  horizontalColumnPropertyChanged(event) {
    var data = this.state.data;
    data["isHorizontalColumn"] = !data["isHorizontalColumn"];
    data["textAlignment"] =
      data["isHorizontalColumn"] === false ? "center" : "left";
    this.dataChanged(data);
  }
  distributeEquallyPropertyChanged(event) {
    var data = this.state.data;
    data["isDistributeEqually"] = !data["isDistributeEqually"];
    this.dataChanged(data);
  }
  spaceEvenlyPropertyChanged(event) {
    var data = this.state.data;
    data["isSpaceEvenly"] = !data["isSpaceEvenly"];
    this.dataChanged(data);
  }

  columnHeadingChanged(event, index) {
    var data = this.state.data;
    data["columns"][index]["heading"] = event.target.value;
    this.dataChanged(data);
  }
  columnTextChanged(event, index) {
    var data = this.state.data;
    data["columns"][index]["text"] = event.target.value;
    this.dataChanged(data);
  }
  columnURLChanged(event, index) {
    var data = this.state.data;
    data["columns"][index]["url"] = event.target.value;
    this.dataChanged(data);
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  imagePopupImageSelected(img) {
    var data = this.state.data;
    if (this.selectedImageSectionType === "M") {
      data["image"] = img;
    } else {
      data["columns"][this.selectedColumnImageIndex]["image"] = img;
    }
    this.dataChanged(data);
  }

  mainImageSelectionTapped = () => {
    this.selectedImageSectionType = "M";
    this.props.onImageTapped();
  };

  columnImageSelectionTapped = (index) => {
    this.selectedImageSectionType = "C";
    this.selectedColumnImageIndex = index;
    this.props.onImageTapped();
  };
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  dataChanged(data) {
    this.setState(
      {
        data: data,
      },
      () => {
        this.props.onComponentDataChanged(this.state.data);
      }
    );
  }

  //////////////////////////////////////////////////

  getListPickerArrow() {
    return (
      <img
        className="ListPickerArrowStyle"
        src={require("./Assets/ic_listPickerArrow2.png")}
        alt=""
      />
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  render() {
    return (
      <div
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          padding: 6,
        }}
      >
        <text
          style={{
            fontSize: 16,
            fontWeight: "bold",
            marginTop: 5,
            marginLeft: 2,
            marginBottom: 8,
            // color: this.COLOR_BLUE,
          }}
        >
          Settings
        </text>
        {this.state.data.type === componentType.BUTTON_SECTION &&
          this.getTextViewSection()}
        {this.state.data.type === componentType.BUTTON_SECTION &&
          this.getButtonURLSection()}
        {this.state.data &&
          (this.state.data.type === componentType.BANNER ||
            this.state.data.type === componentType.IMAGE_WITH_TEXT) &&
          this.getImageSelectSection()}
        {this.state.data &&
          this.state.data.type === componentType.IMAGE_WITH_TEXT &&
          this.getImageAlignmentListPicker()}
        {this.state.data &&
          (this.state.data.type === componentType.IMAGE_WITH_TEXT ||
            this.state.data.type === componentType.ROW_WITH_COLUMNS ||
            this.state.data.type === componentType.COLLECTION ||
            this.state.data.type === componentType.TESTIMONIALS ||
            this.state.data.type === componentType.BLOG ||
            this.state.data.type === componentType.PHOTO_GALLERY ||
            this.state.data.type === componentType.BUTTON_SECTION) &&
          this.getImageShapeListPicker()}
        {this.state.data &&
          (this.state.data.type === componentType.ROW_WITH_COLUMNS ||
            this.state.data.type === componentType.COLLECTION ||
            this.state.data.type === componentType.TESTIMONIALS ||
            this.state.data.type === componentType.BLOG ||
            this.state.data.type === componentType.PHOTO_GALLERY) &&
          this.getImageHiddenCheckBox()}
        {this.state.data &&
          this.state.data.type === componentType.BANNER &&
          this.getSectionHeightListPicker()}
        {this.getTextSizeListPicker()}
        {this.state.data && this.getTextAlignmentListPicker()}
        {this.state.data && this.getTextColorPicker()}
        {this.state.data.type === componentType.BUTTON_SECTION &&
          this.getButtonColorPicker()}
        {this.getBackgroundColorPicker()}
        {this.state.data &&
          this.state.data.type !== componentType.BUTTON_SECTION &&
          this.getHeadingSection()}
        {this.state.data.type !== componentType.BUTTON_SECTION &&
          this.getTextViewSection()}

        {this.getPaddingSection()}

        {this.state.data &&
          (this.state.data.type === componentType.ROW_WITH_COLUMNS ||
            this.state.data.type === componentType.COLLECTION ||
            this.state.data.type === componentType.TESTIMONIALS ||
            this.state.data.type === componentType.BLOG ||
            this.state.data.type === componentType.PHOTO_GALLERY) &&
          this.getColumnSection(this.state.data.columns)}
        <div style={{ height: 100 }} />
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getVerticalPaddingSettingsSection() {
    return (
      <div
        className="RowDiv"
        style={{ marginLeft: 12, paddingTop: 5, paddingBottom: 5 }}
      >
        <text style={{ fontSize: 12, fontWeight: "700", marginRight: 15 }}>
          Vertical Padding
        </text>
        {this.getPaddingButton("-", false, true)}
        {this.getPaddingButton("+", true, true)}
      </div>
    );
  }
  getHorizontalPaddingSettingsSection() {
    return (
      <div
        className="RowDiv"
        style={{ marginLeft: 12, paddingTop: 5, paddingBottom: 5 }}
      >
        <text style={{ fontSize: 12, fontWeight: "700", marginRight: 15 }}>
          Horizontal Padding
        </text>
        {this.getPaddingButton("-", false, false)}
        {this.getPaddingButton("+", true, false)}
      </div>
    );
  }
  verticalPaddingChanged = (isIncreased) => {
    if (isIncreased) {
      this.verticalPaddingIncreased();
    } else {
      this.verticalPaddingDecreased();
    }
  };
  horizontalPaddingChanged = (isIncreased) => {
    if (isIncreased) {
      this.horizontalPaddingIncreased();
    } else {
      this.horizontalPaddingDecreased();
    }
  };
  getPaddingButton(title, isIncreased, isVertical) {
    return (
      <button
        style={{
          backgroundColor: "rgb(245, 245, 245)",
          color: "black",
          borderRadius: 4,
          fontSize: 15,
          cursor: "pointer",
          border: "solid",
          borderColor: "rgb(230, 230, 230)",
          borderWidth: 1,
          outline: "none",
          marginRight: 10,
          padding: 4,
          paddingRight: 10,
          paddingLeft: 10,
        }}
        onClick={
          isVertical
            ? () => this.verticalPaddingChanged(isIncreased)
            : () => this.horizontalPaddingChanged(isIncreased)
        }
      >
        {title}
      </button>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getSectionHeader(str, fontSize = 12) {
    return (
      <text
        style={{
          fontSize: fontSize,
          color: "black",
          marginTop: 15,
          marginBottom: 5,
          marginLeft: 3,
          fontWeight: "700",
        }}
      >
        {str}
      </text>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getImageHiddenCheckBox() {
    return (
      <div style={{ marginTop: 15 }}>
        {this.getSectionHeader("Show Image")}
        <input
          type="checkbox"
          value={this.state.data ? !this.state.data.isImageHidden : false}
          checked={this.state.data ? !this.state.data.isImageHidden : false}
          onChange={this.imageHiddenPropertyChanged}
          style={{ marginLeft: 10 }}
        ></input>
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getTextColorPicker() {
    return (
      <div style={{ marginTop: 15 }}>
        {this.getSectionHeader("Text Color")}
        <input
          type="color"
          className="ColorPicker"
          style={{
            marginLeft: 10,
          }}
          value={this.state.data ? this.state.data.textColor : "#ffffff"}
          onChange={this.textColorChanged}
        />
      </div>
    );
  }
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getButtonColorPicker() {
    return (
      <div style={{ marginTop: 15 }}>
        {this.getSectionHeader("Button Color")}
        <input
          type="color"
          className="ColorPicker"
          style={{
            marginLeft: 15,
          }}
          value={this.state.data ? this.state.data.buttonColor : "#ffffff"}
          onChange={this.buttonColorChanged}
        />
      </div>
    );
  }
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getBackgroundColorPicker() {
    return (
      <div style={{ marginTop: 15 }}>
        {this.getSectionHeader("Section Color")}
        <input
          type="color"
          className="ColorPicker"
          style={{
            marginLeft: 10,
          }}
          value={
            this.state.data.backgroundColor
              ? this.state.data.backgroundColor
              : "#ffffff"
          }
          onChange={this.backgroundColorChanged}
        />
      </div>
    );
  }
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getTextViewSection() {
    return (
      <div className="ListPickerHolderStyle">
        {this.getSectionHeader("Text")}
        <textarea
          className="TextField"
          style={{
            minHeight:
              this.state.data.type === componentType.BUTTON_SECTION ? 40 : 80,
            fontFamily: "helvetica",
            fontSize: 13,
          }}
          placeholder=""
          type="text"
          value={this.state.data ? this.state.data.text : ""}
          onChange={this.textChanged}
        />
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getButtonURLSection() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Web URL")}
        <input
          className="TextField"
          placeholder="https://example.com"
          type="text"
          value={this.state.data.webURL ? this.state.data.webURL : ""}
          onChange={this.webURLChanged}
        />
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getHeadingSection() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Heading")}
        <input
          className="TextField"
          placeholder=""
          type="text"
          value={this.state.data ? this.state.data.heading : ""}
          onChange={this.headingChanged}
        />
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getTextSizeListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Text Size")}
        <select onChange={this.textSizeChanged} className="ListPicker">
          <option value="small" selected={this.state.data.textSize === "small"}>
            Small
          </option>
          <option
            value="medium"
            selected={this.state.data.textSize === "medium"}
          >
            Medium
          </option>
          <option value="large" selected={this.state.data.textSize === "large"}>
            Large
          </option>
          <option
            value="extra-large"
            selected={this.state.data.textSize === "extra-large"}
          >
            Extra Large
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getTextAlignmentListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader(
          this.state.data.type === componentType.BUTTON_SECTION
            ? "Button Alignment"
            : "Text Alignment"
        )}
        <select onChange={this.textAlignmentChanged} className="ListPicker">
          <option
            value="left"
            selected={this.state.data.textAlignment === "left"}
          >
            Left
          </option>
          <option
            value="center"
            selected={this.state.data.textAlignment === "center"}
          >
            Center
          </option>
          <option
            value="right"
            selected={this.state.data.textAlignment === "right"}
          >
            Right
          </option>
          <option
            value="justify"
            selected={this.state.data.textAlignment === "justify"}
          >
            Justify
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getSectionHeightListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Section Height")}
        <select onChange={this.sectionHeightChanged} className="ListPicker">
          <option
            value="auto"
            selected={this.state.data.sectionHeight === "auto"}
          >
            Auto (Aspect Fit)
          </option>
          <option
            value="extraSmall"
            selected={this.state.data.sectionHeight === "extraSmall"}
          >
            Extra Small
          </option>
          <option
            value="small"
            selected={this.state.data.sectionHeight === "small"}
          >
            Small
          </option>
          <option
            value="medium"
            selected={this.state.data.sectionHeight === "medium"}
          >
            Medium
          </option>
          <option
            value="large"
            selected={this.state.data.sectionHeight === "large"}
          >
            Large
          </option>
          <option
            value="extraLarge"
            selected={this.state.data.sectionHeight === "extraLarge"}
          >
            Extra Large
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getImageAlignmentListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Image Alignment")}
        <select onChange={this.imageAlignmentChanged} className="ListPicker">
          <option
            value="left"
            selected={this.state.data.imageAlignment === "left"}
          >
            Left
          </option>
          <option
            value="right"
            selected={this.state.data.imageAlignment === "right"}
          >
            Right
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getImageShapeListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader(
          this.state.data.type === componentType.BUTTON_SECTION
            ? "Button Shape"
            : "Image Shape"
        )}
        <select onChange={this.imageShapeChanged} className="ListPicker">
          <option
            value="square"
            selected={this.state.data.imageShape === "square"}
          >
            Square
          </option>
          <option
            value="circular"
            selected={this.state.data.imageShape === "circular"}
          >
            Circular
          </option>
          <option
            value="cornerRadius"
            selected={this.state.data.imageShape === "cornerRadius"}
          >
            Corner Radius
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getPaddingSection() {
    return (
      <div className="ColumnDiv" style={{ marginTop: 25, width: "100%" }}>
        {this.getSectionHeader("Section Padding", 16)}
        <div
          className="ColumnDiv"
          style={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 8,
            border: "solid",
            borderWidth: 1,
            borderColor: "rgb(230, 230, 230)",
            paddingTop: 15,
            paddingBottom: 15,
            marginTop: 10,
          }}
        >
          {this.getVerticalPaddingSettingsSection()}
          {this.getHorizontalPaddingSettingsSection()}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getImageSelectSection() {
    return (
      <div
        className="ColumnDiv"
        style={{
          width: "100%",
        }}
      >
        {this.getSectionHeader("Image")}
        <img
          src={
            this.state.data && this.state.data.image
              ? this.state.data.image
              : require("./Assets/ic_thumbnail_default.png")
          }
          alt=""
          style={{
            width: "100%",
            objectFit: "cover",
            borderRadius: 6,
            marginTop: 10,
            marginBottom: 12,
            cursor: "pointer",
          }}
          onClick={this.mainImageSelectionTapped}
        />
        {/* <input
          type="file"
          accept=".png,.jpg,.jpeg,.bitmap"
          onChange={this.handleSelectedFile}
        /> */}
        <button
          style={{
            backgroundColor: "rgb(230, 230, 230)",
            color: "black",
            borderRadius: 8,
            fontSize: 13,
            // fontWeight: "600",
            cursor: "pointer",
            border: "solid",
            outline: "none",
            padding: 10,
            paddingTop: 7,
            paddingBottom: 7,
            borderWidth: 1,
            borderColor: "rgb(200, 200, 200)",
            marginBottom: 10,
          }}
          onClick={this.mainImageSelectionTapped}
        >
          Choose file
        </button>
        {/* {ComponentManager.getButton(
          "Choose file",
          "rgb(200, 200, 200)",
          this.mainImageSelectionTapped
        )} */}
      </div>
    );
  }

  //////////////////////////////////////////////////
  ///////////////// COLUMN SECTION /////////////////
  //////////////////////////////////////////////////

  getColumnSection(columnsData) {
    return (
      <div
        style={{
          marginTop: 35,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          width: "100%",
        }}
      >
        {this.getSectionHeader("Column Settings", 16)}
        <div
          className="ColumnDiv TextField"
          style={{
            marginTop: 10,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          {this.getDistributeEquallyCheckBox()}
          {this.getSpaceEvenlyCheckBox()}
          {this.getColumnHorizontalCheckBox()}
          {!this.state.data.isDistributeEqually &&
            this.getNumberOfColumnsListPicker()}
        </div>

        <div className="ColumnDiv" style={{ width: "100%", marginTop: 25 }}>
          {this.getAllColumns(columnsData)}
        </div>
        {this.getColumnButton()}
      </div>
    );
  }

  ////////////////////
  ////////////////////
  getDistributeEquallyCheckBox() {
    return (
      <div style={{ marginBottom: 12 }}>
        {this.getSectionHeader("Distribute Equally ")}
        <input
          type="checkbox"
          value={
            this.state.data.isDistributeEqually
              ? this.state.data.isDistributeEqually
              : false
          }
          checked={
            this.state.data.isDistributeEqually
              ? this.state.data.isDistributeEqually
              : false
          }
          onChange={this.distributeEquallyPropertyChanged}
          style={{ marginLeft: 5 }}
        ></input>
      </div>
    );
  }
  getSpaceEvenlyCheckBox() {
    return (
      <div style={{ marginBottom: 12 }}>
        {this.getSectionHeader("Space Evenly ")}
        <input
          type="checkbox"
          value={
            this.state.data.isSpaceEvenly
              ? this.state.data.isSpaceEvenly
              : false
          }
          checked={
            this.state.data.isSpaceEvenly
              ? this.state.data.isSpaceEvenly
              : false
          }
          onChange={this.spaceEvenlyPropertyChanged}
          style={{ marginLeft: 5 }}
        ></input>
      </div>
    );
  }
  getColumnHorizontalCheckBox() {
    return (
      <div style={{ marginBottom: 5 }}>
        {this.getSectionHeader("Plot Horizontal ")}
        <input
          type="checkbox"
          value={
            this.state.data.isHorizontalColumn
              ? this.state.data.isHorizontalColumn
              : false
          }
          checked={
            this.state.data.isHorizontalColumn
              ? this.state.data.isHorizontalColumn
              : false
          }
          onChange={this.horizontalColumnPropertyChanged}
          style={{ marginLeft: 5 }}
        ></input>
      </div>
    );
  }
  ////////////////////
  ////////////////////

  getNumberOfColumnsListPicker() {
    return (
      <div className="ListPickerHolderStyle">
        <div style={{ height: 10 }} />
        {this.getSectionHeader("Columns per row")}
        <select
          onChange={this.numberOfColumnsPerRowChanged}
          className="ListPicker"
        >
          {this.getAllNumberOfColumnListOptions()}
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }

  getAllNumberOfColumnListOptions() {
    let arr = [];
    for (let i = 1; i <= 10; i++) {
      arr.push(
        <option
          value={i}
          selected={
            "" + this.state.data.numberOfColumnsPerRow === "" + i ? true : false
          }
        >
          {i + " columns"}
        </option>
      );
    }
    return arr;
  }

  ////////////////////
  ////////////////////

  getAllColumns(columnsData) {
    var arr = [];
    for (let i = 0; i < columnsData.length; i++) {
      arr.push(this.getColumn(i, columnsData[i]));
    }
    return arr;
  }
  getColumn(index, columnData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 20,
          marginTop: 10,
          width: "100%",
          position: "relative",
        }}
      >
        <div>
          <img
            src={
              columnData.image
                ? columnData.image
                : require("./Assets/ic_column_thumbnail_default.png")
            }
            alt=""
            style={{
              width: 60,
              height: 60,
              marginLeft: -10,
              objectFit: "cover",
              border: "solid",
              borderWidth: 1,
              borderRadius: 6,
              borderColor: "rgb(220, 220, 220)",
              cursor: "pointer",
            }}
            onClick={() => this.columnImageSelectionTapped(index)}
          />
          <button
            style={{
              marginRight: 10,
              cursor: "pointer",
              color: "red",
              border: "solid",
              borderWidth: 1,
              borderColor: "rgb(210, 210, 210)",
              backgroundColor: "white",
              borderRadius: 4,
            }}
            onClick={() => this.deleteColumn(index)}
          >
            Delete
          </button>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <input
            style={{
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "left",
              color: "black",
              border: "solid",
              borderWidth: 1,
              borderColor: "rgb(230, 230, 230)",
              backgroundColor: "white",
              borderRadius: 6,
              padding: 10,
              paddingTop: 6,
              paddingBottom: 6,
            }}
            placeholder="Heading"
            type="text"
            value={columnData.heading ? columnData.heading : ""}
            onChange={(e) => this.columnHeadingChanged(e, index)}
          />

          <textarea
            style={{
              minHeight: 70,
              fontFamily: "helvetica",
              fontSize: 12,
              textAlign: "left",
              padding: 10,
              backgroundColor: "white",
              border: "solid",
              borderColor: "rgb(230, 230, 230)",
              borderWidth: 1,
              borderRadius: 6,
              marginTop: 5,
            }}
            placeholder="Text"
            type="text"
            value={columnData.text}
            onChange={(e) => this.columnTextChanged(e, index)}
          />

          <input
            style={{
              fontSize: 12,
              textAlign: "left",
              color: COLOR_BLUE,
              border: "solid",
              borderWidth: 1,
              borderColor: "rgb(230, 230, 230)",
              backgroundColor: "white",
              borderRadius: 6,
              padding: 10,
              paddingTop: 6,
              paddingBottom: 6,
              marginTop: 5,
            }}
            placeholder="Web URL"
            type="text"
            value={columnData.url ? columnData.url : ""}
            onChange={(e) => this.columnURLChanged(e, index)}
          />

          <text
            style={{ fontSize: 12, textAlign: "left", color: "black" }}
          ></text>
        </div>
      </div>
    );
  }

  getColumnButton() {
    return (
      <div
        style={{
          borderRadius: 6,
          border: "solid",
          borderWidth: 1,
          borderColor: "rgb(200, 200, 200)",
          width: "90%",
          backgroundColor: "white",
          cursor: "pointer",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
        }}
        onClick={() => this.getNewColumn()}
      >
        <text style={{ fontSize: 13, textAlign: "center", color: "black" }}>
          + Add New Column
        </text>
      </div>
    );
  }

  getNewColumn() {
    var data = this.state.data;
    data["columns"] = [
      ...data["columns"],
      this.getNewColumnData(this.state.data.type),
    ];
    this.dataChanged(data);
  }

  getNewColumnId() {
    let id = 0;
    let columns = this.state.data.columns;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id > id) {
        id = columns[i].id;
      }
    }
    return id + 1;
  }

  getColumnDataHeader(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return "Author's name";
      case componentType.BLOG:
        return "Blog Title";
      case componentType.PHOTO_GALLERY:
        return "";
      case componentType.COLLECTION:
        return "Your product name";
      case componentType.ROW_WITH_COLUMNS:
        return "Add a title or tagline";
      default:
        return "TITLE";
    }
  }
  getColumnDataText(type) {
    switch (type) {
      case componentType.TESTIMONIALS:
        return "Add customer reviews and testimonials to showcase your store’s happy customers.";
      case componentType.BLOG:
        return "Share blog posts, products, or promotions with your customers.";
      case componentType.PHOTO_GALLERY:
        return "";
      case componentType.COLLECTION:
        return "S$ 19.99";
      case componentType.ROW_WITH_COLUMNS:
        return "Share blog posts, products, or promotions with your customers. Use this text to describe products, share details on availability and style, or as a space to display recent reviews or FAQs.";
      default:
        return "TEXT";
    }
  }

  getNewColumnData(type) {
    return {
      id: this.getNewColumnId(),
      type: componentType.COLUMN,
      image: null,
      heading: this.getColumnDataHeader(type),
      text: this.getColumnDataText(type),
    };
  }

  deleteColumn(index) {
    var data = this.state.data;
    data["columns"].splice(index, 1);
    this.dataChanged(data);
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
}
