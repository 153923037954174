import React, { Component } from "react";
import { COLOR_BLUE } from "../Constants";
import StoreManager from "./Managers/StoreManager";
import DKUtility from "../DKUtility";

/*
buttonTitle
callback
totalAmount
*/

export default class PriceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAmount: this.props.totalAmount,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      totalAmount: nextProps.totalAmount,
    });
  }

  render() {
    return (
      <div
        className="column parent-width"
        style={{
          padding: 20,
          boxSizing: "border-box",
          //   overflowY: "scroll",
        }}
      >
        <text className="fw-b" style={{ fontSize: 13, marginBottom: 20 }}>
          PRICE DETAILS
        </text>
        {this.getAmountRow(
          "Sub total",
          DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
            " " +
            DKUtility.toCurrencyFormat(this.state.totalAmount)
        )}
        {this.getAmountRow(
          "Discount",
          DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
            " 0.00",
          "black"
        )}
        {this.getAmountRow(
          "Tax",
          DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
            " 0.00",
          "black"
        )}
        {this.getAmountRow("Delivery Fee", "NA")}
        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "lightGray",
            marginTop: 20,
            opacity: 0.5,
          }}
        />
        {this.getAmountRow(
          "Total",
          DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
            " " +
            DKUtility.toCurrencyFormat(this.state.totalAmount),
          "black",
          16,
          "bold"
        )}
        {this.props.buttonTitle && (
          <div
            className="parent-width border-radius-m cursor-hand fw-m"
            style={{
              padding: 15,
              backgroundColor:
                parseFloat(this.state.totalAmount) === 0
                  ? "rgb(180, 180, 180)"
                  : COLOR_BLUE,
              color: "white",
              boxSizing: "border-box",
              marginTop: 30,
              marginBottom: 20,
            }}
            onClick={this.props.callback}
          >
            <text>{this.props.buttonTitle}</text>
          </div>
        )}
        <text
          className="fw-b"
          style={{
            fontSize: 14,
            marginBottom: 6,
            marginTop: this.props.buttonTitle ? 0 : 40,
          }}
        >
          Notes
        </text>
        <text
          className="text-align-left"
          style={{ fontSize: 12, color: "gray" }}
        >
          Enterprise Ecommerce / How to Sell Online. Delight your customers (and
          make managing your orders easy) with these 7 tips.
        </text>
      </div>
    );
  }

  getAmountRow(
    title,
    value,
    valueColor = "black",
    fontSize = 13,
    fontWeight = "normal"
  ) {
    return (
      <div
        className="row justify-content-between"
        style={{ paddingTop: 8, paddingBottom: 8 }}
      >
        <text
          className=""
          style={{ fontSize: fontSize, color: "black", fontWeight: fontWeight }}
        >
          {title}
        </text>
        <text
          className=""
          style={{
            fontSize: fontSize,
            color: valueColor,
            fontWeight: fontWeight,
          }}
        >
          {value}
        </text>
      </div>
    );
  }
}
