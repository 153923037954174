export default class Template5 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838211855_Screenshot%202020-09-23%20at%201.15.56%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838224267_Screenshot%202020-09-23%20at%201.16.02%20PM.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "extraSmall",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838232882_Screenshot%202020-09-23%20at%201.16.09%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838224267_Screenshot%202020-09-23%20at%201.16.02%20PM.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 2,
        paddingHorizontal: 10,
      },
      {
        id: 5,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "right",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838224267_Screenshot%202020-09-23%20at%201.16.02%20PM.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 2,
        paddingHorizontal: 10,
      },
      {
        id: 6,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838224267_Screenshot%202020-09-23%20at%201.16.02%20PM.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 2,
        paddingHorizontal: 10,
      },
      {
        id: 7,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "extraSmall",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600838232882_Screenshot%202020-09-23%20at%201.16.09%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
    ];
  }
}
