import React, { Component } from "react";
// import "../css/AllCSS.js";
import DKSectionHeader from "./DKSectionHeader";
import DKSpaceV from "./DKSpaceV";
// import DKContactIcon from "./DKContactIcon";
import DKSpaceH from "./DKSpaceH";
import DKLabel from "./DKLabel";
import DKButton from "./DKButton";
// import { icons } from "../constants/Icons";
// import { colors } from "../constants/Colors.js";
// import DKNoDataView from "./DKNoDataView.js";
// import { getCapitalized } from "../DKUtility.js";
// import DKSpinner from "./DKSpinner.js";

/*
PROPS
    - className
    - title
    - needIcon
    - data
    - onSelect={(index, value)}
    - onClose
    - width
    - onNewCreate
*/

export default class DKListPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInit: this.props.data,
      data: this.props.data,
      textFieldValue: "",
    };

    this.padding = 15;
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      dataInit: nextProps.data,
      data: nextProps.data,
    });
  }
  render() {
    return (
      <div
        className={"column shadow-m border-radius-s " + this.props.className}
        style={{
          ...{
            backgroundColor: "white",
            width: this.props.width ? this.props.width : null,
            padding: 0,
            paddingBottom: this.padding,
            maxHeight: "80%",
          },
          ...this.props.style,
        }}
      >
        <div className="parent-width">
          <div
            style={{ padding: this.padding, paddingBottom: this.padding / 2 }}
          >
            <div className="row justify-content-between">
              <DKSectionHeader text={this.props.title} />
              <DKButton
                className="text-black bg-transparent pt-none pb-none "
                onClick={this.props.onClose}
                title="X"
              />
            </div>
            {this.state.dataInit && this.state.dataInit.length > 4 && (
              <DKSpaceV value={10} />
            )}
            {this.state.dataInit &&
              this.state.dataInit.length > 4 &&
              this.getTextField()}
          </div>

          <div
            style={{
              overflowY: "scroll",
              // maxHeight: 300,
            }}
          >
            {this.state.data !== undefined &&
              this.state.data !== null &&
              this.getList(this.state.data, this.props.needIcon)}
          </div>
          {(this.state.data === undefined || this.state.data === null) &&
            this.getLoading()}
          {/* {this.state.data === undefined ||
            this.state.data === null ||
            (this.state.data.length === 0 && <DKNoDataView />)} */}
          {this.props.onNewCreate && (
            <div
              style={{
                padding: this.padding,
                paddingTop: this.padding / 2,
                paddingBottom: this.padding / 2,
              }}
            >
              <DKButton title="+ Create New" onClick={this.props.onNewCreate} />
            </div>
          )}
        </div>
      </div>
    );
  }

  getLoading() {
    return (
      <div className="column align-items-center mt-l mb-l">
        {/* <DKSpinner /> */}
        {/* <DKLabel className="fs-m text-gray mt-xs" text="Loading..." /> */}
      </div>
    );
  }

  getList(list, needIcon) {
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      arr.push(this.listItem(list[i], i, needIcon));
    }
    return arr;
  }
  listItem(title, index, needIcon) {
    return (
      <div
        className="row listPickerBG"
        style={{
          padding: 9,
          paddingLeft: this.padding,
          paddingRight: this.padding,
          cursor: "pointer",
        }}
        onClick={() => {
          this.props.onSelect(this.state.dataInit.indexOf(title), title);
        }}
      >
        {/* {needIcon && <DKContactIcon title={title} />} */}
        {needIcon && <DKSpaceH value={8} />}
        <DKLabel text={title} />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  getTextField() {
    return (
      <input
        className="TextField border-radius-s"
        placeholder="Type here"
        type="text"
        value={this.state.textFieldValue}
        onChange={this.textChanged}
        style={{
          backgroundColor: "rgb(240, 240, 240)",
          padding: 6,
          boxSizing: "border-box",
          width: "100%",
        }}
      />
    );
  }

  textChanged = (event) => {
    this.setState({
      textFieldValue: event.target.value,
    });
    this.filterDataFor(event.target.value);
  };

  filterDataFor(value) {
    let mainData = this.state.dataInit;
    let filteredData = mainData.filter((obj) =>
      obj.toLowerCase().startsWith(value.toLowerCase())
    );
    this.setState({
      data: value === "" ? mainData : filteredData,
    });
  }
}
