export default class ImageManager {
  static uploadedImages = [];

  static setImages(images) {
    ImageManager.uploadedImages = images;
  }

  static getAllUploadedImages() {
    return ImageManager.uploadedImages;
  }

  static addNewImage(imagePath) {
    ImageManager.uploadedImages.push(imagePath);
  }
}
