import React, { Component } from "react";
// import "../css/AllCSS.js";
import DKLabel from "./DKLabel";

/*
PROPS
    - className
*/

export default class DKLoading extends Component {
  render() {
    return (
      <div
        className="transparent-background"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      >
        <div
          className="popup-window column align-items-center"
          style={{
            width: 160,
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <div className="row justify-content-center">
            <div
              className="Loading-logo"
              style={{ opacity: 0.3, marginRight: 8 }}
            >
              {/* {Components.getIcon(require("../Assets/ic_loading.png"), 16)} */}
            </div>
            <DKLabel text="Loading..." />
          </div>
        </div>
      </div>
    );
  }
}
