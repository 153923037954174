import React, { Component } from "react";

/* 
PROPS
    - value
*/

export default class DKSpaceH extends Component {
  render() {
    return <div style={{ width: this.props.value }} />;
  }
}
