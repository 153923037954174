import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import AppManager from "./AppManager";
import { MAX_WIDTH } from "./Constants";

export default class ButtonSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponentID: this.props.currentComponentID,
      data: this.props.data,
      isReadOnly: this.props.isReadOnly,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (
      this.state.currentComponentID === this.state.data.id ||
      this.state.currentComponentID === null
    ) {
      this.setState({
        data: nextProp.data,
      });
    }
    this.setState({
      isReadOnly: nextProp.isReadOnly,
    });
  }

  getButtonPadding(sizeType) {
    if (sizeType === "small") {
      return "1.0";
    } else if (sizeType === "medium") {
      return "1.2";
    } else if (sizeType === "large") {
      return "1.6";
    } else if (sizeType === "extra-large") {
      return "2.0";
    }
    return "1.4";
  }
  getTextWeight(sizeType) {
    if (sizeType === "small") {
      return "700";
    } else if (sizeType === "medium") {
      return "700";
    } else if (sizeType === "large") {
      return "700";
    } else if (sizeType === "extra-large") {
      return "700";
    }
    return "normal";
  }
  getButtonRadius(buttonShape) {
    if (buttonShape === "square") {
      return 0;
    } else if (buttonShape === "circular") {
      return this.getButtonPadding(this.state.data.textSize) * 2;
    }
    return this.getButtonPadding(this.state.data.textSize) / 1.5;
  }

  render() {
    let paddingVertical = this.state.data.paddingVertical;
    let paddingHorizontal = this.state.data.paddingHorizontal;

    return (
      <div
        className={
          "PaddingHorizontal" +
          paddingHorizontal +
          " PaddingVertical" +
          paddingVertical
        }
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent:
            this.state.data.textAlignment === "left"
              ? "flex-start"
              : this.state.data.textAlignment === "right"
              ? "flex-end"
              : "center",
          backgroundColor: this.state.data.backgroundColor
            ? this.state.data.backgroundColor
            : "white",
          position: "relative",
        }}
      >
        {ComponentManager.getWideScreenSectionBackground(
          this.state.data.backgroundColor
        )}

        <button
          className={ComponentManager.getTextFontSize(this.state.data.textSize)}
          onClick={this.checkToOpenWebURL}
          style={{
            backgroundColor: this.state.data.buttonColor,
            color: this.state.data.textColor,
            fontWeight: this.getTextWeight(this.state.data.textSize),
            paddingTop:
              this.getButtonPadding(this.state.data.textSize) / 1.3 + "vw",
            paddingBottom:
              this.getButtonPadding(this.state.data.textSize) / 1.3 + "vw",
            paddingLeft:
              this.getButtonPadding(this.state.data.textSize) * 1.3 + "vw",
            paddingRight:
              this.getButtonPadding(this.state.data.textSize) * 1.3 + "vw",
            border: "none",
            outline: "none",
            borderRadius:
              this.getButtonRadius(this.state.data.imageShape) + "vw",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          {this.state.data.text}
        </button>
      </div>
    );
  }

  checkToOpenWebURL = () => {
    if (
      this.state.isReadOnly &&
      this.state.data.webURL &&
      this.state.data.webURL !== ""
    ) {
      AppManager.openWebURL(this.state.data.webURL);
    }
  };
}
