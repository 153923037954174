import ApiConstants from "./ApiConstants";
import ApiManager from "./ApiManager";
import WebsiteManager from "./WebsiteManager";

export default class MenuApiManager {
  static getMenuDetails(presenter) {
    let url =
      ApiConstants.URL.BASE + ApiConstants.URL.MENU_CRUD_API + "?limit=40";

    fetch(url, ApiManager.getApiRequestOptions("GET"))
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 400) {
          presenter.menuDataReceived(null);
        } else {
          presenter.menuDataReceived(JSON.parse(data.menu_json));
        }
      })
      .catch((error) => {
        ApiManager.checkIfSessionExpired(error, true);
      });
  }

  static createMenu(menuJSON, presenter) {
    let base64JSON = Buffer.from(JSON.stringify(menuJSON)).toString("base64");
    let dataToPost = {
      menu_json: base64JSON,
      website_id: WebsiteManager.getWebsiteID(),
    };
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.MENU_CRUD_API,
      ApiManager.getApiRequestOptions("POST", dataToPost)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          // DO NOTHING ITS ALREADY SET TO DEFAULT
          // presenter.menuSettingDetailsReceived(dataToPost.menu_json);
          presenter.menuCreated(menuJSON);
        }
      })
      .catch((error) => {
        ApiManager.checkIfSessionExpired(error, true);
      });
  }

  static updateMenu(menuJSON, presenter) {
    let base64JSON = Buffer.from(JSON.stringify(menuJSON)).toString("base64");
    let dataToPost = {
      menu_json: base64JSON,
      website_id: WebsiteManager.getWebsiteID(),
    };

    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.MENU_CRUD_API,
      ApiManager.getApiRequestOptions("PUT", dataToPost)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          presenter.menuUpdatedSuccess();
        } else {
          presenter.menuUpdateFailed();
          alert("Menu data not saved. Internal error occured");
        }
      })
      .catch((error) => {
        presenter.menuUpdateFailed();
        ApiManager.checkIfSessionExpired(error, true);
      });
  }
}
