import ApiConstants from "./ApiConstants";
import ApiManager from "./ApiManager";
import WebsiteManager from "./WebsiteManager";
import PageManager from "./PageManager";
import AppManager from "./AppManager";

export default class PageApiManager {
  static getProductPageData() {
    return {
      content_json: "[{}]",
      content_json_draft: "",
      created_at: "2020-11-05T02:53:18.385948Z",
      id: 9999,
      is_active: true,
      sequence_no: -1,
      tenant_id: -1,
      title: "Products",
      updated_at: "2020-11-05T03:22:55.759335Z",
      website_id: -1,
    };
  }
  static getAllWebpages(presenter) {
    let url =
      ApiConstants.URL.BASE +
      ApiConstants.URL.WEBSITE_PAGE_CRUD_API +
      "?limit=40";

    fetch(url, ApiManager.getApiRequestOptions("GET"))
      .then((response) => response.json())
      .then((data) => {
        presenter.pagesDataReceived(data.data);
      })
      .catch((error) => {
        alert(error);
      });
  }

  static createPage(name, pageJSON, presenter) {
    let base64JSON = Buffer.from(JSON.stringify(pageJSON)).toString("base64");
    let dataToPost = {
      content_json: base64JSON,
      content_json_draft: "",
      is_active: true,
      sequence_no: PageManager.getAllPages().length,
      title: name,
      website_id: WebsiteManager.getWebsiteID(),
    };
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.WEBSITE_PAGE_CRUD_API,
      ApiManager.getApiRequestOptions("POST", dataToPost)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          presenter.pageCreatedSuccess(data.Id, name, pageJSON);
        } else {
          presenter.pageCreateFailed();
          alert(JSON.stringify(data));
        }
      })
      .catch((error) => {
        presenter.pageCreateFailed();
        ApiManager.checkIfSessionExpired(error, true);
      });
  }

  static updatePage(pageJSON, presenter, callback = null) {
    let pageID = PageManager.getCurrentPageID();
    let pageSequenceNo = PageManager.getCurrentPageSequenceNo();
    let base64JSON = Buffer.from(JSON.stringify(pageJSON)).toString("base64");
    console.log(JSON.stringify(pageJSON));
    let dataToPost = {
      content_json: base64JSON,
      content_json_draft: "",
      is_active: true,
      sequence_no: pageSequenceNo,
      title: PageManager.getCurrentPageTitle(),
      website_id: WebsiteManager.getWebsiteID(),
    };

    fetch(
      ApiConstants.URL.BASE +
        ApiConstants.URL.WEBSITE_PAGE_CRUD_API +
        "/" +
        pageID,
      ApiManager.getApiRequestOptions("PUT", dataToPost)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          AppManager.dataSaved();
        }

        if (callback !== null) {
          if (data.Success === true) {
            callback();
          } else {
            alert("Page data not saved. Internal error occured");
          }
        }
      })
      .catch((error) => {
        ApiManager.checkIfSessionExpired(error, callback !== null);
      });
  }
}
