import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import { COLOR_BLUE } from "./Constants";
import MenuApiManager from "./MenuApiManager";
import MenuManager from "./MenuManager";
import ApiConstants from "./ApiConstants";

export default class EditWebsitePopup extends Component {
  constructor(props) {
    super(props);

    this.initSettings = MenuManager.getSettingsCopy(this.props.menuSettings);

    this.state = {
      menuSettings: this.props.menuSettings,
      title: "Settings",
      subTitle: "Customize your websiite menu ",
      createButtonTitle: "Save",
      websiteLogo: this.props.websiteLogo,
      apiCalled: false,
      waitMsg: "Please wait...",
      pageCreated: false,
    };
  }

  //Will be called on cancel
  resetSettings() {
    var menuSettings = this.state.menuSettings;
    menuSettings["logo"] = this.initSettings.logo;
    menuSettings["title"] = this.initSettings.title;
    menuSettings["layoutType"] = this.initSettings.layoutType;
    menuSettings["menuHeight"] = this.initSettings.menuHeight;
    menuSettings["backgroundColor"] = this.initSettings.backgroundColor;
    menuSettings["textColor"] = this.initSettings.textColor;
    menuSettings["selectedTextColor"] = this.initSettings.selectedTextColor;
    menuSettings["titleColor"] = this.initSettings.titleColor;
    menuSettings[
      "selectedMenuButtonType"
    ] = this.initSettings.selectedMenuButtonType;
    menuSettings["shadowRequired"] = this.initSettings.shadowRequired;
    menuSettings["autoHideMenu"] = this.initSettings.autoHideMenu;
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.websiteLogo !== this.state.menuSettings.logo) {
      var menuSettings = this.state.menuSettings;
      menuSettings["logo"] = nextProp.websiteLogo;
      this.dataChanged();
    }

    this.setState({
      websiteLogo: nextProp.websiteLogo,
    });
  }

  dataChanged() {
    this.props.onDataChanged(this.state.menuSettings);
  }

  websiteNameChanged = (e) => {
    if (!this.state.apiCalled) {
      var menuSettings = this.state.menuSettings;
      menuSettings["title"] = e.target.value;
      this.setState({ menuSettings: menuSettings });
      this.dataChanged();
    }
  };

  render() {
    return (
      <div
        className="WindowPopup"
        style={{
          color: "black",
          paddingLeft: 30,
          paddingRight: 30,
          width: 450,
          height: "86vh",
          overflowY: "scroll",
        }}
      >
        <div style={{ position: "absolute", right: 15, top: 20 }}>
          {ComponentManager.getButton(
            this.state.createButtonTitle,
            COLOR_BLUE,
            this.updateWebsiteTapped
          )}
        </div>
        <div style={{ position: "absolute", right: 75, top: 20 }}>
          {ComponentManager.getButton(
            "Cancel",
            "rgb(180, 180, 180)",
            this.cancelTapped
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            className="ColumnDiv"
            style={{
              width: "100%",
              textAlign: "left",
              fontSize: 16,
            }}
          >
            <text style={{ fontSize: 25, fontWeight: "bold" }}>
              {this.state.title}
            </text>
            <text style={{ marginTop: 6, color: "gray", fontSize: 13 }}>
              {this.state.subTitle}
            </text>
            <br />
            {!this.state.pageCreated && (
              <div
                className="ColumnDiv"
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <div
                  className="RowDiv"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <img
                    src={
                      this.state.websiteLogo === null ||
                      this.state.websiteLogo === "" ||
                      this.state.websiteLogo === undefined
                        ? require("./Assets/ic_logo1.png")
                        : this.state.websiteLogo
                    }
                    alt=""
                    style={{
                      height: 50,
                      marginRight: 15,
                      borderRadius: 6,
                      cursor: "pointer",
                    }}
                    onClick={this.props.onImageTapped}
                  />
                  <div className="ColumnDiv">
                    <text style={{ fontSize: 13 }}>Website logo</text>
                    <text
                      style={{
                        fontSize: 12,
                        backgroundColor: "gray",
                        padding: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 6,
                        marginTop: 5,
                        color: "white",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={this.props.onImageTapped}
                    >
                      Edit
                    </text>
                  </div>
                </div>
                <input
                  className="TextField"
                  style={{ marginBottom: 0 }}
                  placeholder="Website name"
                  type="text"
                  value={
                    this.state.menuSettings.title === " "
                      ? ""
                      : this.state.menuSettings.title
                  }
                  onChange={this.websiteNameChanged}
                />
              </div>
            )}
          </div>
        </div>
        {this.getMenuHeightOptions()}
        {this.getLayoutOptions()}
        {this.getColorOptions()}
        {this.getShadowHCheckBox()}
        {this.getMenuHiddenCheckBox()}
        <div style={{ height: 20 }} />

        {this.state.apiCalled && (
          <text style={{ fontSize: 13, color: COLOR_BLUE, fontWeight: "700" }}>
            {this.state.waitMsg}
          </text>
        )}
        <div style={{ height: 15 }} />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  getSectionHeader(str, fontSize = 18) {
    return (
      <text
        style={{
          fontSize: fontSize,
          color: "black",
          marginTop: 34,
          marginBottom: 10,
          fontWeight: "700",
        }}
      >
        {str}
      </text>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  getColorOptions() {
    return (
      <div className="ColumnDiv">
        {this.getSectionHeader("Custom Colors")}
        <div className="RowDiv" style={{ marginTop: -10 }}>
          {this.getColorSectionFor(
            "Button text color ",
            this.menuButtonColorChanged,
            this.state.menuSettings.textColor
          )}
          {this.getColorSectionFor(
            "Selected button color ",
            this.menuSelectedButtonColorChanged,
            this.state.menuSettings.selectedTextColor
          )}
        </div>
        <div className="RowDiv">
          {this.getColorSectionFor(
            "Background color",
            this.menuBackgroundColorChanged,
            this.state.menuSettings.backgroundColor
          )}
          {this.getColorSectionFor(
            "Title/Website name color ",
            this.menuTitleColorChanged,
            this.state.menuSettings.titleColor
          )}
        </div>
      </div>
    );
  }
  getColorSectionFor(title, callback, value) {
    return (
      <div className="RowDiv" style={{ marginTop: 15 }}>
        <text style={{ fontSize: 13 }}>{title}</text>
        <input
          type="color"
          className="ColorPicker"
          style={{
            marginLeft: 10,
          }}
          value={value}
          onChange={(event) => callback(event)}
        />
      </div>
    );
  }

  menuButtonColorChanged = (event) => {
    let color = event.target.value;
    var menuSettings = this.state.menuSettings;
    menuSettings["textColor"] = color;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };
  menuSelectedButtonColorChanged = (event) => {
    let color = event.target.value;
    var menuSettings = this.state.menuSettings;
    menuSettings["selectedTextColor"] = color;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };
  menuTitleColorChanged = (event) => {
    let color = event.target.value;
    var menuSettings = this.state.menuSettings;
    menuSettings["titleColor"] = color;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };
  menuBackgroundColorChanged = (event) => {
    let color = event.target.value;
    var menuSettings = this.state.menuSettings;
    menuSettings["backgroundColor"] = color;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  menuHeightChanged = (event) => {
    var menuSettings = this.state.menuSettings;
    menuSettings["menuHeight"] = event.target.value;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };

  getMenuHeightListPicker() {
    return (
      <div
        className="ListPickerHolderStyle"
        style={{ width: "auto", flex: 0.96 }}
      >
        <select onChange={this.menuHeightChanged} className="ListPicker">
          <option
            value="small"
            selected={this.state.menuSettings.menuHeight === "small"}
          >
            Small
          </option>
          <option
            value="medium"
            selected={this.state.menuSettings.menuHeight === "medium"}
          >
            Medium
          </option>
          <option
            value="large"
            selected={this.state.menuSettings.menuHeight === "large"}
          >
            Large
          </option>
        </select>
        {this.getListPickerArrow()}
      </div>
    );
  }
  getListPickerArrow() {
    return (
      <img
        className="ListPickerArrowStyle"
        src={require("./Assets/ic_listPickerArrow2.png")}
        alt=""
      />
    );
  }

  getMenuHeightOptions() {
    return (
      <div className="RowDiv" style={{ marginTop: 30 }}>
        <div style={{ marginRight: 20 }}>
          {this.getSectionHeader("Menu Height")}
        </div>
        {this.getMenuHeightListPicker()}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  getLayoutOptions() {
    return (
      <div className="ColumnDiv">
        {this.getSectionHeader("Menu Layouts")}
        <div className="RowDiv">{this.getAllLayoutButtons()}</div>
      </div>
    );
  }
  getAllLayoutButtons() {
    let numberOfLayouts = 6;
    let arr = [];
    for (let i = 1; i <= numberOfLayouts; i++) {
      arr.push(
        <div
          style={{
            paddingTop: 3,
            paddingBottom: 3,
            width: 100 / numberOfLayouts + "%",
            fontSize: 12,
            backgroundColor:
              this.state.menuSettings.layoutType === "" + i
                ? "white"
                : "rgb(240, 240, 240)",
            cursor: "pointer",
            marginRight: 8,
            border: "solid",
            borderWidth: this.state.menuSettings.layoutType === "" + i ? 2 : 0,
            borderRadius: 6,
            borderColor: COLOR_BLUE,
          }}
          onClick={() => this.layoutTypeChanged("" + i)}
        >
          {"Layout " + i}
        </div>
      );
    }
    return arr;
  }

  layoutTypeChanged = (type) => {
    var menuSettings = this.state.menuSettings;
    menuSettings["layoutType"] = type;
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  shadowValueChanged = (event) => {
    var menuSettings = this.state.menuSettings;
    menuSettings["shadowRequired"] = !menuSettings["shadowRequired"];
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };

  getShadowHCheckBox() {
    return (
      <div className="ColumnDiv">
        {this.getSectionHeader("Menu Shadow")}
        <div className="RowDiv" style={{ marginTop: 1 }}>
          <text style={{ fontSize: 13 }}>
            Need shadow at the bottom of header
          </text>
          <input
            type="checkbox"
            value={this.state.menuSettings.shadowRequired}
            checked={this.state.menuSettings.shadowRequired}
            onChange={(e) => this.shadowValueChanged(e)}
            style={{ marginLeft: 10 }}
          ></input>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  menuHiddenValueChanged = (event) => {
    var menuSettings = this.state.menuSettings;
    menuSettings["autoHideMenu"] = !menuSettings["autoHideMenu"];
    this.setState({ menuSettings: menuSettings });
    this.dataChanged();
  };

  getMenuHiddenCheckBox() {
    return (
      <div className="ColumnDiv">
        {this.getSectionHeader("Auto Hide Menu")}
        <div className="RowDiv" style={{ marginTop: 1 }}>
          <text style={{ fontSize: 13 }}>
            Automatically show / hide menu on mouse scroll
          </text>
          <input
            type="checkbox"
            value={this.state.menuSettings.autoHideMenu}
            checked={this.state.menuSettings.autoHideMenu}
            onChange={(e) => this.menuHiddenValueChanged(e)}
            style={{ marginLeft: 10 }}
          ></input>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  cancelTapped = () => {
    this.resetSettings();

    this.setState(
      {
        menuSettings: this.state.menuSettings,
      },
      () => {
        this.dataChanged();
        this.props.onCancel();
      }
    );
  };
  updateWebsiteTapped = () => {
    let websiteName = this.state.menuSettings.title;
    if (websiteName === "") {
      websiteName = " ";
    }
    if (
      this.state.menuSettings.tenantId === null ||
      this.state.menuSettings.tenantId === undefined
    ) {
      var menuSettings = this.state.menuSettings;
      menuSettings["tenantId"] = ApiConstants.TENANT_ID;
      this.setState({ menuSettings: menuSettings });
    }

    this.setState({
      apiCalled: true,
    });

    MenuApiManager.updateMenu(this.state.menuSettings, this);
  };

  menuUpdatedSuccess() {
    this.setState({
      apiCalled: false,
    });
    alert("Your menu settings have been updated successfully.");

    this.props.onUpdate();
  }
  menuUpdateFailed() {
    this.setState({
      apiCalled: false,
    });
  }
}
