import React, { Component } from "react";

/* 
PROPS
    - value
*/

export default class DKSpaceV extends Component {
  render() {
    return <div style={{ height: this.props.value }} />;
  }
}
