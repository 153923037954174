import ApiConstants from "./ApiConstants";
import AppManager from "./AppManager";

export default class ApiManager {
  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getApiRequestOptions(method, body = null) {
    var requestOptions = {
      method: method,
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    };
    if (method === "POST" || method === "PUT") {
      body = JSON.stringify(body);
      requestOptions = { ...requestOptions, body };
    }
    return requestOptions;
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getPublicWebURL(presenter) {
    let domain = window.location.hostname;
 
    fetch(
      ApiConstants.URL.BASE +
        ApiConstants.URL.PUBLIC_URL +
        "?domain=" + domain,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.subdomain) {
          presenter.publicURLReceived("https://" + data.subdomain);
        }
      })
      .catch((error) => {});
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static checkIfUserLoggedIn(presenter) {
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.LOGIN_STATUS,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        presenter.loginStatusApiResponsedReceived(data);
      })
      .catch((error) => {
        presenter.loginStatusApiResponsedReceived(null);
      });
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static checkIfSessionExpired(error, needToShowAlert = true) {
    if (error.toString() === "TypeError: Failed to fetch") {
      alert("Your session is expired. Please login again.");
      AppManager.gotoLoginPage();
    } else {
      if (needToShowAlert) {
        alert(error);
      }
    }
  }
}
