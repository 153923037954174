import ProductManager from "./ProductManager";

export default class CartManager {
  static products = [];
  static orderNumber = "-";

  static setProducts(data) {
    CartManager.products = data;
  }
  static getProducts() {
    return CartManager.products;
  }

  static addProduct(data) {
    if (!CartManager.isProductAlreadyInCart(data)) {
      CartManager.products.splice(0, 0, ProductManager.getCopyOfProduct(data));
    }
  }
  static deleteProduct(index) {
    CartManager.products.splice(index, 1);
  }
  static updateProduct(index, data) {
    CartManager.products[index] = data;
  }

  static emptyCart() {
    CartManager.setProducts([]);
  }

  static isProductAlreadyInCart(data) {
    for (let i = 0; i < CartManager.products.length; i++) {
      let product = CartManager.products[i];
      if (product.id === data.id) {
        product["quantity"] = parseFloat(product["quantity"]) + 1;
        return true;
      }
    }
    return false;
  }

  static getTotalAmount() {
    let total = 0;
    for (let i = 0; i < CartManager.products.length; i++) {
      let product = CartManager.products[i];
      total =
        total + parseFloat(product.quantity) * parseFloat(product.sellingPrice);
    }
    return total;
  }

  static setOrderNumber(code) {
    CartManager.orderNumber = code;
  }
  static getOrderNumber() {
    return CartManager.orderNumber;
  }
}
