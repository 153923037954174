import React, { Component } from "react";
// import "../App.css";

/* 
PROPS
    - className
    - text
*/

export default class DKSectionHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      text: nextProp.text,
    });
  }

  render() {
    return (
      <text
        className={"fs-l fw-b text-align-left " + this.props.className}
        style={{
          ...{},
          ...this.props.style,
        }}
      >
        {this.state.text}
      </text>
    );
  }
}
