import React, { Component } from "react";
import "./App.css";
import Column from "./Column";
import ComponentManager from "./ComponentManager";
import { MAX_WIDTH } from "./Constants";

export default class RowWithColumnsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponentID: this.props.currentComponentID,
      data: this.props.data,
      isReadOnly: this.props.isReadOnly,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (
      this.state.currentComponentID === this.state.data.id ||
      this.state.currentComponentID === null
    ) {
      this.setState({
        data: nextProp.data,
      });
    }
    this.setState({
      isReadOnly: nextProp.isReadOnly,
    });
  }

  render() {
    let flexWrap = this.state.data.isDistributeEqually ? "noWrap" : "wrap";
    let justfyContent = this.state.data.isSpaceEvenly
      ? "space-evenly"
      : "initial";

    let paddingVertical = this.state.data.paddingVertical;
    let paddingHorizontal = this.state.data.paddingHorizontal;

    return (
      <div className="RowDiv">
        <div
          className={
            "ColumnDiv " +
            "PaddingHorizontal" +
            paddingHorizontal +
            " PaddingVertical" +
            paddingVertical
          }
          style={{
            width: "100%",
            backgroundColor: this.state.data.backgroundColor
              ? this.state.data.backgroundColor
              : "white",
            position: "relative",
          }}
        >
          {ComponentManager.getWideScreenSectionBackground(
            this.state.data.backgroundColor
          )}

          <text
            className={ComponentManager.getHeaderFontSize(
              this.state.data.textSize
            )}
            style={{
              width: "100%",
              fontWeight: "bold",
              color: this.state.data.textColor,
              textAlign: this.state.data.textAlignment,
              zIndex: 2,
            }}
          >
            {this.state.data.heading}
          </text>
          <text
            className={ComponentManager.getTextFontSize(
              this.state.data.textSize
            )}
            style={{
              width: "100%",
              color: this.state.data.textColor,
              opacity: 0.6,
              marginTop: 5,
              textAlign: this.state.data.textAlignment,
              whiteSpace: "pre-wrap",
              zIndex: 2,
            }}
          >
            {this.state.data.text}
          </text>
          {this.state.data.columns && this.state.data.columns.length > 0 && (
            <div
              className="RowDiv"
              style={{
                flexWrap: flexWrap,
                justifyContent: justfyContent,
                alignItems: "flex-start",
                zIndex: 2,
              }}
            >
              {this.getAllColumns(this.state.data.columns)}
            </div>
          )}
        </div>
      </div>
    );
  }
  getAllColumns(columnsData) {
    // let noOfColumns = this.state.data.isDistributeEqually
    //   ? columnsData.length
    //   : this.state.data.isHorizontalColumn
    //   ? 3
    //     : 4;
    let noOfColumns = this.state.data.isDistributeEqually
      ? columnsData.length
      : this.state.data.numberOfColumnsPerRow;
    let gapBetweenColumn = 2;

    let holderWidth = 100 - noOfColumns * gapBetweenColumn;

    let widthPercentOfColumn = holderWidth / noOfColumns;

    let rightMarginPercentOfColumn = this.state.data.isDistributeEqually
      ? 2
      : 2;
    let bottomMarginPercentOfColumn = this.state.data.isDistributeEqually
      ? 0
      : 2;

    var arr = [];
    for (let i = 0; i < columnsData.length; i++) {
      arr.push(
        this.getColumn(
          columnsData[i],
          widthPercentOfColumn,
          rightMarginPercentOfColumn,
          bottomMarginPercentOfColumn
        )
      );
    }
    return arr;
  }
  getColumn(columnData, width, marginRight, marginBottom) {
    return (
      <Column
        isReadOnly={this.state.isReadOnly}
        currentColumnID={columnData.id}
        data={columnData}
        width={width}
        marginRight={marginRight}
        marginBottom={marginBottom}
        textSize={this.state.data.textSize}
        textColor={this.state.data.textColor}
        textAlignment={this.state.data.textAlignment}
        imageShape={this.state.data.imageShape}
        isImageHidden={this.state.data.isImageHidden}
        isHorizontalColumn={this.state.data.isHorizontalColumn}
      />
    );
  }
}
