import React, { Component } from "react";
import ProductThumbnail from "./ProductThumbnail";
import ComponentManager from "../ComponentManager";

export default class ProductPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }

  render() {
    return (
      <div className="transparent-background">
        <div className="column popup-window">
          <div
            className="row justify-content-between"
            style={{ marginBottom: 20 }}
          >
            <text className="fw-b" style={{ fontSize: 22 }}>
              Product details
            </text>
            {ComponentManager.getImageButton(
              require("../Assets/ic_close.png"),
              this.props.onClose,
              0,
              15
            )}
          </div>
          <ProductThumbnail
            data={this.state.data}
            onClick={this.props.onClose}
            isFromPopup={true}
            onAddToCart={this.props.onAddToCart}
          />
          <text className="fw-b" style={{ fontSize: 16, marginBottom: 10 }}>
            Description
          </text>
          <text
            className="text-gray text-align-left parent-width"
            style={{
              fontSize: 13,
              whiteSpace: "pre-wrap",
            }}
          >
            {this.props.data.description}
          </text>
          <div style={{ height: 100, width: 50, backgroundColor: "red" }} />
        </div>
      </div>
    );
  }
}
