import React, { Component } from "react";
import "../../Common.css";
import DKLabel from "./DKLabel";
import DKSpaceH from "./DKSpaceH";
import { getCapitalized } from "../../DKUtility.js";
// import DKIcon from "./DKIcon.js";
/* 
PROPS
    - className
    - placeHolder
    - icon
    - value
    - isInValid
    - onChange(text)
    - onClick
    - isEditable
    - isUpperCase
    - isLowerCase
    - isTextArea
    - type / DKTextFieldType
*/

export const DKTextFieldType = {
  TEXT: "Text",
  NUMBER: "Number",
  STATIC: "Static",
  PASSWORD: "Password",
};

export default class DKTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      isInValid: this.props.isInValid ? this.props.isInValid : false,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      value: nextProp.value,
      isInValid: nextProp.isInValid,
    });
  }

  render() {
    const leftSectionWidth = "30%";
    const rightSectionWidth = "68%";
    const gap = "2%";

    return (
      <div
        className={"row parent-width border-radius-r " + this.props.className}
        style={{ paddingTop: 6, paddingBottom: 6 }}
        onClick={this.props.onClick ? () => this.props.onClick() : null}
      >
        <div className="row" style={{ width: leftSectionWidth }}>
          {/* <DKIcon className="mr-s" src={this.props.icon} /> */}
          <DKLabel text={this.props.placeHolder} style={{ color: "black" }} />
        </div>
        <DKSpaceH value={gap} />
        <div
          className={
            "row border-radius-s bg-gray1 " +
            (this.state.isInValid === true ? "border-red " : "")
          }
          style={{
            width: rightSectionWidth,
            padding: 12,
          }}
        >
          {!this.props.isTextArea && this.getTextField()}
          {this.props.isTextArea && this.getTextArea()}
        </div>
      </div>
    );
  }

  getTextField() {
    return (
      <input
        className="TextField2"
        placeholder="Empty"
        type={
          this.props.type === DKTextFieldType.PASSWORD ? "password" : "text"
        }
        value={this.state.value}
        onChange={this.textChanged}
        style={{
          pointerEvents:
            this.props.isEditable === false ||
            this.props.type === DKTextFieldType.STATIC
              ? "none"
              : "auto",
        }}
      />
    );
  }
  getTextArea() {
    return (
      <textarea
        className="TextField"
        style={{
          resize: "none",
          minHeight: 60,
          maxHeight: 60,
          fontFamily: "helvetica",
          fontSize: 13,
          pointerEvents:
            this.props.isEditable === false ||
            this.props.type === DKTextFieldType.STATIC
              ? "none"
              : "auto",
        }}
        placeholder="Empty"
        type="text"
        value={this.state.value}
        onChange={this.textChanged}
      />
    );
  }

  textChanged = (event) => {
    if (this.props.onChange) {
      if (this.props.type && this.props.type === DKTextFieldType.NUMBER) {
        if (isNaN(event.target.value)) {
          return;
        }
      }
      this.props.onChange(
        this.props.isUpperCase === true
          ? event.target.value.toUpperCase()
          : this.props.isLowerCase
          ? event.target.value
          : getCapitalized(event.target.value)
      );
    }
  };
}
