export default class Template3 {
  static getData() {
    return [
      {
        id: 1,
        type: "Photo Gallery",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836013661_Screenshot%202020-09-23%20at%2012.36.09%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836020227_Screenshot%202020-09-23%20at%2012.36.15%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836025374_Screenshot%202020-09-23%20at%2012.36.22%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 3,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836035872_Screenshot%202020-09-23%20at%2012.36.28%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 0,
      },
      {
        id: 3,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "Collection Title",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836056689_Screenshot%202020-09-23%20at%2012.36.47%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836063870_Screenshot%202020-09-23%20at%2012.37.04%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836071334_Screenshot%202020-09-23%20at%2012.37.38%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836077938_Screenshot%202020-09-23%20at%2012.37.53%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 9,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600836094096_Screenshot%202020-09-23%20at%2012.38.06%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
    ];
  }
}
