import ComponentManager, { componentType } from "../ComponentManager";

export default class Template1 {
  static getData() {
    return [
      ComponentManager.getBannerData(0, componentType.BANNER, "auto"),
      ComponentManager.getImageWithTextSectionData(1, componentType.PARAGRAPH),
      ComponentManager.getImageWithTextSectionData(
        2,
        componentType.IMAGE_WITH_TEXT
      ),
      ComponentManager.getImageWithTextSectionData(
        3,
        componentType.IMAGE_WITH_TEXT
      ),
      ComponentManager.getImageWithTextSectionData(
        4,
        componentType.IMAGE_WITH_TEXT
      ),
      ComponentManager.getRowWithColumnsSectionData(
        6,
        componentType.ROW_WITH_COLUMNS
      ),
      ComponentManager.getBannerData(7, componentType.BANNER, "small"),
      ComponentManager.getRowWithColumnsSectionData(
        9,
        componentType.TESTIMONIALS
      ),
    ];
  }
}
