import React, { Component } from "react";
import "./App.css";
import ComponentManager, { componentType } from "./ComponentManager";
import { MAX_WIDTH } from "./Constants";

export default class ImageAndTextSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionEmail: "",
      currentComponentID: this.props.currentComponentID,
      data: this.props.data,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (
      this.state.currentComponentID === this.state.data.id ||
      this.state.currentComponentID === null
    ) {
      this.setState({
        data: nextProp.data,
      });
    }
  }

  render() {
    let paddingVertical = this.state.data.paddingVertical;
    let paddingHorizontal = this.state.data.paddingHorizontal;

    return (
      <div
        className={
          "PaddingHorizontal" +
          paddingHorizontal +
          " PaddingVertical" +
          paddingVertical
        }
        style={{
          backgroundColor: this.state.data.backgroundColor
            ? this.state.data.backgroundColor
            : "white",
          position: "relative",
        }}
      >
        {ComponentManager.getWideScreenSectionBackground(
          this.state.data.backgroundColor
        )}

        <div
          className={
            this.state.data.type !== componentType.PARAGRAPH &&
            this.state.data.type !== componentType.NEWS_LETTER
              ? this.state.data.imageAlignment === "left"
                ? "RowDiv"
                : "RowReverseDiv"
              : null
          }
          style={{ width: "100%" }}
        >
          {this.state.data.type !== componentType.PARAGRAPH &&
            this.state.data.type !== componentType.NEWS_LETTER && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={
                    this.state.data.image
                      ? this.state.data.image
                      : require("./Assets/ic_thumbnail_default.png")
                  }
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius:
                      this.state.data.imageShape === "circular"
                        ? "50%"
                        : this.state.data.imageShape === "cornerRadius"
                        ? "20%"
                        : "0%",
                  }}
                />
              </div>
            )}
          {this.state.data.type !== componentType.PARAGRAPH &&
            this.state.data.type !== componentType.NEWS_LETTER && (
              <div
                style={{
                  width: 4 + "vw",
                }}
              />
            )}
          <div
            className="ColumnDiv"
            style={{
              position: "relative",
              width:
                this.state.data.type === componentType.PARAGRAPH ||
                this.state.data.type === componentType.NEWS_LETTER
                  ? null
                  : "100%",
            }}
          >
            <text
              className={ComponentManager.getHeaderFontSize(
                this.state.data.textSize
              )}
              style={{
                width: "100%",
                fontWeight: "bold",
                color: this.state.data.textColor,
                textAlign: this.state.data.textAlignment,
              }}
            >
              {this.state.data.heading}
            </text>
            <text
              className={ComponentManager.getTextFontSize(
                this.state.data.textSize
              )}
              style={{
                width: "100%",
                color: this.state.data.textColor,
                opacity: 0.6,
                marginTop: 5,
                textAlign: this.state.data.textAlignment,
                whiteSpace: "pre-wrap",
              }}
            >
              {this.state.data.text}
            </text>
            {this.state.data.type === componentType.NEWS_LETTER &&
              this.getNewsLetterSection()}
          </div>
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  emailAddressChanged = (event) => {
    this.setState({
      subscriptionEmail: event.target.value,
    });
  };

  getNewsLetterSection() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 5,
          width: "100%",
          justifyContent: "center",
          marginTop: 15,
        }}
      >
        <input
          className="TextField"
          placeholder="Enter your email address to subscribe"
          type="text"
          value={
            this.state.subscriptionEmail ? this.state.subscriptionEmail : ""
          }
          onChange={this.emailAddressChanged}
          style={{ width: "50%" }}
        />
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
            border: "none",
            borderRadius: 6,
            height: 40,
            marginLeft: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }}
          onClick={() =>
            this.setState({
              isAddComponentPopupRequired: !this.state
                .isAddComponentPopupRequired,
            })
          }
        >
          Subscribe
        </button>
      </div>
    );
  }
}
