import StoreApiClient from "./eCommerce/API/StoreApiClient";
import StoreManager from "./eCommerce/Managers/StoreManager";
import ApiConstants from "./ApiConstants";
import WebsiteApiManager from "./WebsiteApiManager";
import ApiManager from "./ApiManager";
import AppManager from "./AppManager";
import FileServiceManager from "./FileServiceManager";
import DomainManager from "./DomainManager";
import WebsiteManager from "./WebsiteManager";
import PageApiManager from "./PageApiManager";
import PageManager from "./PageManager";
import MenuApiManager from "./MenuApiManager";
import MenuManager from "./MenuManager";

export default class ApiManagerInitial {
  static presenter = null;

  callInitialApis(presenter) {
    ApiManagerInitial.presenter = presenter;
    this.checkIfPublicURL();
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 1  ///////////////////////////
  ////////////////////////////////////////////////////////////

  checkIfPublicURL() {
    if (
      window.location.host !== ApiConstants.BUILDER_URL &&
      window.location.host !== ApiConstants.LOCALHOST_URL
    ) {
      this.getPublicWebURL();
    } else {
      this.checkIfUserLoggedIn();
    }
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 2  ///////////////////////////
  ////////////////////////////////////////////////////////////

  getPublicWebURL() {
    ApiManager.getPublicWebURL(this);
  }
  publicURLReceived(url) {
    ApiConstants.setPublicURLS(url);
    this.getWebsiteDetails();
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 2  ///////////////////////////
  ////////////////////////////////////////////////////////////

  checkIfUserLoggedIn() {
    ApiManager.checkIfUserLoggedIn(this);
  }

  loginStatusApiResponsedReceived(data) {
    if (data === null || data.accessToken === null) {
      AppManager.gotoLoginPage();
      return;
    }
    if (data.accessToken) {
      this.tokenReceived(data.accessToken);
      AppManager.userLoggedIn();
      FileServiceManager.getAllUploadedImages();
      this.getWebsiteDetails();
    }
  }

  tokenReceived(accessToken) {
    let token = ApiManager.parseJwt(accessToken);
    ApiConstants.ACCESS_TOKEN = accessToken;
    ApiConstants.TENANT_ID = token.tenantId;
    ApiConstants.TENANT_NAME = token.website;
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 3  ///////////////////////////
  ////////////////////////////////////////////////////////////

  getWebsiteDetails() {
    WebsiteApiManager.getWebsiteDetails(this);
  }

  websiteDataReceived(data) {
    if (data) {
      if (
        data.subdomain !== null &&
        data.subdomain !== "" &&
        data.subdomain !== undefined
      ) {
        DomainManager.domainCreated(data.subdomain);
      }

      WebsiteManager.websiteCreated(data);
      this.getPagesData();
    } else {
      this.createWebsite();
    }
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 4  ///////////////////////////
  ////////////////////////////////////////////////////////////

  createWebsite() {
    let websiteURL = "domain" + ApiConstants.TENANT_ID;
    let websiteName = "My Website";
    WebsiteApiManager.createWebsite(websiteName, websiteURL, this);
  }

  websiteCreated(data) {
    WebsiteManager.websiteCreated(data);
    this.getPagesData();
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 5  ///////////////////////////
  ////////////////////////////////////////////////////////////

  getPagesData() {
    PageApiManager.getAllWebpages(this);
  }

  pagesDataReceived(data) {
    PageManager.setAllPages(data);
    this.getMenuDetails();
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 6  ///////////////////////////
  ////////////////////////////////////////////////////////////

  getMenuDetails() {
    MenuApiManager.getMenuDetails(this);
  }
  menuDataReceived(data) {
    if (data) {
      MenuManager.setMenuSettings(data);
      this.getStoreInfo();
    } else {
      this.createMenu();
    }
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 7  ///////////////////////////
  ////////////////////////////////////////////////////////////

  createMenu() {
    MenuApiManager.createMenu(MenuManager.getDefaultMenuSettings(), this);
  }
  menuCreated(data) {
    this.menuDataReceived(data);
  }

  ////////////////////////////////////////////////////////////
  ///////////////////////  STEP 8  ///////////////////////////
  ////////////////////////////////////////////////////////////

  getStoreInfo() {
    StoreApiClient.getStoreInfo(this);
  }
  storeDataReceived(data) {
    if (data) {
      StoreManager.setStoreData(data);
    }

    ApiManagerInitial.presenter.allInitialApisCallsDone();
  }
}
