import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import PageManager from "./PageManager";
import { COLOR_BLUE } from "./Constants";

export default class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: this.props.pages,
      isReadOnly: this.props.isReadOnly,
      isUserLoggedIn: this.props.isUserLoggedIn,
      backgroundColor: "#ffffff",
      menuSettings: this.props.menuSettings,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      pages: nextProp.pages,
      isReadOnly: nextProp.isReadOnly,
      isUserLoggedIn: nextProp.isUserLoggedIn,
      menuSettings: nextProp.menuSettings,
    });
  }

  backgroundColorChanged = (event) => {
    this.setState({
      backgroundColor: event.target.value,
    });

    this.props.onBackgroundColorChanged(event.target.value);
  };

  render() {
    return (
      <div
        className={
          this.state.menuSettings && this.state.menuSettings.shadowRequired
            ? "Menu MenuShadow"
            : "Menu"
        }
        style={{
          backgroundColor:
            this.state.menuSettings && this.state.menuSettings.backgroundColor
              ? this.state.menuSettings.backgroundColor
              : "white",
          height:
            this.state.menuSettings &&
            this.state.menuSettings.menuHeight === "small"
              ? "5vh"
              : this.state.menuSettings &&
                this.state.menuSettings.menuHeight === "large"
              ? "11vh"
              : "7.5vh",
        }}
      >
        {this.state.menuSettings && this.getLeftSection()}
        {this.state.isUserLoggedIn &&
          this.state.menuSettings &&
          this.getRightSection()}
      </div>
    );
  }

  getRightSection() {
    return (
      <div
        style={{
          width: this.state.isReadOnly ? 40 : 160,
          // height: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          // paddingRight: 20,
          alignItems: "center",
          backgroundColor: "rgb(235, 235, 235)",
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 8,
          justifyContent: "space-evenly",
          marginRight: 12,

          // border: "solid",
          // borderWidth: 1,
          // borderColor: "rgb(240, 240, 240)",
        }}
      >
        {!this.state.isReadOnly &&
          !this.state.isUserLoggedIn &&
          ComponentManager.getButton(
            "Login",
            COLOR_BLUE,
            this.props.onLoginTapped
          )}

        {!this.state.isReadOnly &&
          this.state.isUserLoggedIn &&
          ComponentManager.getImageButton(
            require("./Assets/menuIcons/ic_publish.png"),
            this.props.onPublishTapped,
            0
          )}
        {!this.state.isReadOnly &&
          this.state.isUserLoggedIn &&
          ComponentManager.getImageButton(
            require("./Assets/menuIcons/ic_save.png"),
            this.props.onSaveTapped,
            0
          )}
        {!this.state.isReadOnly &&
          this.state.isUserLoggedIn &&
          ComponentManager.getImageButton(
            require("./Assets/menuIcons/ic_preview.png"),
            this.props.onFullscreenTapped,
            0
          )}
        {this.state.isReadOnly &&
          this.state.isUserLoggedIn &&
          ComponentManager.getImageButton(
            require("./Assets/menuIcons/ic_previewClose2.png"),
            this.props.onFullscreenTapped,
            0,
            21
          )}
        {!this.state.isReadOnly &&
          this.state.isUserLoggedIn &&
          ComponentManager.getImageButton(
            require("./Assets/menuIcons/ic_edit.png"),
            this.props.onEditTapped
          )}
      </div>
    );
  }
  getLeftSection() {
    let layoutType = this.state.menuSettings.layoutType;
    let mainHolderFlexStyle = "";
    let menuButtonsAlignment = "";

    if (layoutType === "1") {
      mainHolderFlexStyle = "RowDiv";
      menuButtonsAlignment = "flex-start";
    } else if (layoutType === "2") {
      mainHolderFlexStyle = "RowDiv";
      menuButtonsAlignment = "center";
    } else if (layoutType === "3") {
      mainHolderFlexStyle = "RowDiv";
      menuButtonsAlignment = "flex-end";
    } else if (layoutType === "4") {
      mainHolderFlexStyle = "RowReverseDiv";
      menuButtonsAlignment = "flex-start";
    } else if (layoutType === "5") {
      mainHolderFlexStyle = "RowReverseDiv";
      menuButtonsAlignment = "center";
    } else if (layoutType === "6") {
      mainHolderFlexStyle = "RowReverseDiv";
      menuButtonsAlignment = "flex-end";
    }

    return (
      <div
        className={mainHolderFlexStyle}
        style={{
          flex: 1,
          // backgroundColor: "yellow",
          paddingRight: layoutType === "3" ? 5 : 20,
          paddingLeft: 20,
          height: "100%",
        }}
      >
        <img
          src={
            this.state.menuSettings.logo === null ||
            this.state.menuSettings.logo === "" ||
            this.state.menuSettings.logo === undefined
              ? require("./Assets/ic_logo1.png")
              : this.state.menuSettings.logo
          }
          alt=""
          style={{
            height: "4.5vh",
            borderRadius: "0.8vh",
            cursor:
              !this.state.isReadOnly && this.state.isUserLoggedIn
                ? "pointer"
                : "default",
          }}
          onClick={
            !this.state.isReadOnly && this.state.isUserLoggedIn
              ? this.props.onEditTapped
              : null
          }
        />
        {this.state.title !== "" && this.state.title !== " " && (
          <text
            style={{
              fontSize: 18,
              color: this.state.menuSettings.titleColor,
              fontWeight: "bold",
              marginLeft: 15,
              marginRight: 15,
              cursor:
                !this.state.isReadOnly && this.state.isUserLoggedIn
                  ? "pointer"
                  : "default",
            }}
            onClick={
              !this.state.isReadOnly && this.state.isUserLoggedIn
                ? this.props.onEditTapped
                : null
            }
          >
            {this.state.menuSettings.title}
          </text>
        )}
        <div
          className="RowDiv"
          style={{
            flex: 1,
            justifyContent: menuButtonsAlignment,
            // backgroundColor: "pink",
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          {(!this.state.isReadOnly || this.state.pages.length > 0) &&
            this.getAllPagesButtons()}

          {!this.state.isReadOnly && this.state.isUserLoggedIn && (
            <div style={{ width: 10 }} />
          )}
          {!this.state.isReadOnly &&
            this.state.isUserLoggedIn &&
            ComponentManager.getImageButton(
              require("./Assets/menuIcons/ic_addPage.png"),
              this.props.onNewPageTapped,
              5
            )}
        </div>
      </div>
    );
  }

  pageButtonTapped = (index, title, id) => {
    this.props.onPageButtonTapped(index, title, id);
  };
  getAllPagesButtons() {
    var arr = [];
    for (let i = 0; i < this.state.pages.length; i++) {
      arr.push(
        this.getPageButton(i, this.state.pages[i].id, this.state.pages[i].title)
      );
    }
    // arr.push(this.getPageButton(10, 10, "Products"));

    return arr;
  }
  getPageButton(index, id, title) {
    let menuTitle = title;
    if (title === "") {
      menuTitle = "Home page";
    } else if (title === "abot") {
      menuTitle = "About Us";
    }

    menuTitle = this.capitalizeFirstLetter(menuTitle);

    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          cursor: "pointer",
        }}
        onClick={() => this.pageButtonTapped(index, title, id)}
      >
        <text
          style={{
            fontSize: 13,
            color:
              id === PageManager.getCurrentPageID()
                ? this.state.menuSettings.selectedTextColor
                : this.state.menuSettings.textColor,
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor:
              id === PageManager.getCurrentPageID() &&
              this.state.menuSettings.selectedMenuButtonType === "3"
                ? "rgb(240, 240, 240)"
                : "transparent",
            borderRadius: 6,
            fontWeight:
              id === PageManager.getCurrentPageID() ? "bold" : "normal",
          }}
        >
          {id === PageManager.getCurrentPageID() &&
            this.state.menuSettings.selectedMenuButtonType === "1" && (
              <b>{menuTitle}</b>
            )}
          {id === PageManager.getCurrentPageID() &&
            this.state.menuSettings.selectedMenuButtonType === "2" && (
              <u>{menuTitle}</u>
            )}
          {id === PageManager.getCurrentPageID() &&
            this.state.menuSettings.selectedMenuButtonType === "3" && (
              <b>{menuTitle}</b>
            )}
          {id !== PageManager.getCurrentPageID() && menuTitle}
        </text>
      </div>
    );
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
