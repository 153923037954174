import React, { Component } from "react";
import "./App.css";
import ComponentManager, { componentType } from "./ComponentManager";
import { COLOR_BLUE } from "./Constants";

export default class ComponentsHolderPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: this.props.components,
      isAddComponentPopupRequired: false,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      components: nextProp.components,
    });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "start",
          position: "relative",
          padding: 5,
        }}
      >
        <div
          style={{
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
            padding: 10,
            paddingLeft: 5,
            paddingTop: 5,
          }}
        >
          <text>All Sections</text>
        </div>
        <div
          style={{
            width: "100%",
            opacity: this.state.isAddComponentPopupRequired ? 0.2 : 1,
          }}
        >
          {this.getAllComponents()}
          <div style={{ height: 100 }} />
        </div>
        <div style={{ width: "100%", position: "absolute", top: 5 }}>
          {this.state.isAddComponentPopupRequired &&
            this.getComponentListToAdd()}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginRight: 7,
            }}
          >
            {ComponentManager.getButton("+ Add Section", COLOR_BLUE, () =>
              this.setState({
                isAddComponentPopupRequired: !this.state
                  .isAddComponentPopupRequired,
              })
            )}
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  getAllComponents() {
    var arr = [];
    for (let i = 0; i < this.state.components.length; i++) {
      arr.push(this.getComponent(this.state.components[i], i));
    }
    return arr;
  }
  getComponent(data, index) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 55,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          {this.getRowBackgroundButton(data.id)}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <img
              src={this.getComponentIconForType(data.type)}
              alt=""
              style={{ width: 23, marginLeft: 5 }}
            />
            <text
              style={{
                fontSize: 13,
                color: "black",
                textAlign: "left",
                marginLeft: 12,
              }}
            >
              {data.type}
            </text>
          </div>

          <div
            style={{
              width: 100,
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {this.getUpArrow(index)}
            {this.getDownArrow(index)}
            {this.getDeleteButton(index)}
          </div>
        </div>
        <div
          style={{
            height: 1,
            width: "98%",
            backgroundColor: "lightGray",
            opacity: 0.4,
            marginLeft: 6,
          }}
        />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  getUpArrow(index) {
    return (
      <div
        style={{
          height: 16,
          width: 16,
          marginLeft: 6,
          borderRadius: 12,
          opacity: index === 0 ? 0.2 : 0.5,
          cursor: index === 0 ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => (index === 0 ? {} : this.props.onUp(index))}
      >
        <img
          src={require("./Assets/ic_upArrow.png")}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
  getDownArrow(index) {
    return (
      <div
        style={{
          height: 16,
          width: 16,
          marginLeft: 6,
          borderRadius: 12,
          opacity: index === this.state.components.length - 1 ? 0.2 : 0.5,
          cursor:
            index === this.state.components.length - 1 ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() =>
          index === this.state.components.length - 1
            ? {}
            : this.props.onDown(index)
        }
      >
        <img
          src={require("./Assets/ic_downArrow.png")}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }

  getDeleteButton(index) {
    return (
      <div
        style={{
          height: 15,
          width: 15,
          marginLeft: 6,
          borderRadius: 12,
          opacity: 0.5,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => this.props.onDelete(index)}
      >
        <img
          src={require("./Assets/ic_delete.png")}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }

  getRowBackgroundButton(anchorID) {
    return (
      <a
        href={"#" + anchorID}
        style={{ color: "transparent", textDecoration: "none" }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        ></div>
      </a>
    );
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  getComponentIconForType(type) {
    switch (type) {
      case componentType.BANNER:
        return require("./Assets/componentIcons/ic_textWithOverlay.png");
      case componentType.IMAGE_WITH_TEXT:
        return require("./Assets/componentIcons/ic_imageWithText.png");
      case componentType.PARAGRAPH:
        return require("./Assets/componentIcons/ic_paragraph.png");
      case componentType.BLOG:
        return require("./Assets/componentIcons/ic_blog.png");
      case componentType.ROW_WITH_COLUMNS:
        return require("./Assets/componentIcons/ic_columnWithImages.png");
      case componentType.COLLECTION:
        return require("./Assets/componentIcons/ic_collection.png");
      case componentType.PHOTO_GALLERY:
        return require("./Assets/componentIcons/ic_gallery.png");
      case componentType.TESTIMONIALS:
        return require("./Assets/componentIcons/ic_testimonals.png");
      case componentType.NEWS_LETTER:
        return require("./Assets/componentIcons/ic_newsLetter.png");
      case componentType.BUTTON_SECTION:
        return require("./Assets/componentIcons/ic_button.png");
      default:
        return require("./Assets/componentIcons/ic_textWithOverlay.png");
    }
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  getComponentListToAdd() {
    return (
      <div
        style={{
          width: "90%",
          // height: 340,
          backgroundColor: "white",
          borderRadius: 12,
          position: "absolute",
          top: 35,
          left: 2,
          paddingTop: 10,
          paddingBottom: 15,
          paddingLeft: 17,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
        }}
      >
        <div
          className="DownArrow"
          style={{
            position: "absolute",
            top: -10,
            right: 10,
          }}
        ></div>
        <div style={{ marginBottom: 10 }}>
          <text
            style={{
              color: "black",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Component List
          </text>
        </div>
        <div>{this.getAllComponentToAdd()}</div>
      </div>
    );
  }

  getAllComponentToAdd() {
    var arr = [];
    arr.push(this.getComponentToAdd(componentType.BANNER));
    arr.push(this.getComponentToAdd(componentType.IMAGE_WITH_TEXT));
    arr.push(this.getComponentToAdd(componentType.PARAGRAPH));
    arr.push(this.getComponentToAdd(componentType.ROW_WITH_COLUMNS));
    arr.push(this.getComponentToAdd(componentType.COLLECTION));
    arr.push(this.getComponentToAdd(componentType.PHOTO_GALLERY));
    arr.push(this.getComponentToAdd(componentType.BLOG));
    arr.push(this.getComponentToAdd(componentType.TESTIMONIALS));
    arr.push(this.getComponentToAdd(componentType.NEWS_LETTER));
    arr.push(this.getComponentToAdd(componentType.BUTTON_SECTION));
    return arr;
  }
  getComponentToAdd(type) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          padding: 15,
          paddingLeft: 0,
          cursor: "pointer",
        }}
        onClick={() => {
          this.setState({
            isAddComponentPopupRequired: false,
          });
          this.props.onAddNewComponent(type);
        }}
      >
        <img
          src={this.getComponentIconForType(type)}
          alt=""
          style={{ width: 23, marginLeft: 3, marginRight: 14, opacity: 0.85 }}
        />
        <text style={{ color: "black", fontSize: 13 }}>{type}</text>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
}
