import React, { Component } from "react";
import CartManager from "./Managers/CartManager";
import StoreManager from "./Managers/StoreManager";
import DKUtility from "../DKUtility";

export default class ProductThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="parent-width">
        <img
          src={
            this.props.data.images &&
            this.props.data.images.length > 0 &&
            this.props.data.images[0] !== ""
              ? this.props.data.images[0]
              : require("../Assets/ic_thumbnail_default.png")
          }
          alt=""
          className="parent-width cursor-hand"
          onClick={() => this.props.onClick(this.props.index, this.props.data)}
        />
        <div
          className="column parent-width"
          style={{
            padding: 12,
            paddingLeft: this.props.isFromPopup ? 0 : 12,
            overflowY: "hidden",
          }}
        >
          <text
            className="fw-b text-align-left"
            style={{ fontSize: this.props.isFromPopup ? 18 : 15 }}
          >
            {this.props.data.name}
          </text>
          <text
            className="text-align-left text-gray"
            style={{
              marginTop: 4,
              fontSize: 12,
              textOverflow: "ellipsis",
              wordWrap: "breakWord",
              overflow: "hidden",
              maxHeight: "3.6em",
              lineHeight: "1.8em",
            }}
          >
            {this.props.data.description}
          </text>
          <text
            className="fw-b text-align-left"
            style={{
              marginTop: 10,
              fontSize: this.props.isFromPopup ? 22 : 15,
            }}
          >
            {DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
              " " +
              DKUtility.toCurrencyFormat(this.props.data.sellingPrice)}
          </text>
          <div className="row-reverse parent-width" style={{ marginTop: 10 }}>
            <button
              className="cursor-hand"
              onClick={() =>
                this.addToCartTapped(this.props.index, this.props.data)
              }
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    );
  }

  addToCartTapped = (index, data) => {
    CartManager.addProduct(data);
    this.props.onAddToCart();
  };
}
