import React, { Component } from "react";
import TemplateManager from "./TemplateManager";
import { COLOR_BLUE, MAX_WIDTH, POPUP_WIDTH } from "../Constants";

export default class TemplateSelectionPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: this.props.isHidden,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      isHidden: nextProp.isHidden,
    });
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  render() {
    let thumbnailWidth = "21.5%"; //285;
    let thumbnailHeight = "100%"; //thumbnailWidth * 1.25;
    let gap = "3%";

    return (
      <div
        className="WindowPopup"
        style={{
          borderRadius: 6,
          width: "90%",
          height: "100%",
          maxWidth: 1200,
          opacity: this.state.isHidden ? 0 : 1,
          boxShadow: "none",
          backgroundColor: "rgb(245, 245, 245)",
        }}
      >
        {this.getPageHeader()}
        <div
          className="RowDiv"
          style={{
            overflowY: "scroll",
            overflowX: "visible",
            flexWrap: "wrap",
            alignItems: "flex-start",
            paddingBottom: gap,
            marginTop: -10,
          }}
        >
          {this.getAllTemplateThumbnails(thumbnailWidth, thumbnailHeight, gap)}
        </div>
      </div>
    );
  }

  getPageHeader() {
    return (
      <div
        className="ColumnDiv"
        style={{ paddingLeft: "3%", position: "relative" }}
      >
        <img
          src={require("../Assets/ic_logo1.png")}
          alt=""
          style={{ width: 40, borderRadius: 5, marginTop: 20 }}
        />
        <text
          style={{
            fontSize: "1.3vw",
            marginTop: 18,
            color: "rgb(130, 130, 130)",
          }}
        >
          Welcome to
        </text>
        <text
          style={{
            fontSize: "2.3vw",
            fontWeight: "bold",
            marginBottom: 5,
            marginTop: 5,
            color: "rgb(10, 10, 10)",
          }}
        >
          Deskera Website Builder
        </text>
        <text
          style={{
            fontSize: "1.2vw",
            // fontWeight: "bold",
            marginTop: 20,
            color: "rgb(120, 120, 120)",
          }}
        >
          Choose a template and get started!
        </text>
      </div>
    );
  }

  getAllTemplateThumbnails(thumbnailWidth, thumbnailHeight, gap) {
    let arr = [];
    let allTemplates = TemplateManager.getAllTemplates();
    for (let i = 0; i < allTemplates.length; i++) {
      arr.push(
        this.getThumbnail(
          allTemplates[i].id,
          allTemplates[i].thumbnailImage,
          thumbnailWidth,
          thumbnailHeight,
          gap
        )
      );
    }
    return arr;
  }
  getThumbnail(id, image, thumbnailWidth, thumbnailHeight, gap) {
    return (
      <div
        style={{
          width: thumbnailWidth,
          height: thumbnailHeight,
          marginLeft: gap,
          marginTop: gap,
          position: "relative",
        }}
      >
        <img
          className=""
          src={image}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            border: "solid",
            borderWidth: 1,
            borderColor: "rgb(236, 236, 236)",
            backgroundColor: "white",
          }}
        />
        {this.getActionButtonsRow(id)}
      </div>
    );
  }

  getActionButtonsRow(id) {
    return (
      <div
        className="RowReverseDiv"
        style={{
          padding: "0.8vw",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          position: "absolute",
          left: 1,
          bottom: 4,
          boxSizing: "border-box",
        }}
      >
        <button
          style={{
            border: "none",
            borderRadius: 6,
            height: 25,
            backgroundColor: COLOR_BLUE,
            color: "white",
            marginLeft: 8,
            cursor: "pointer",
            outline: "none",
            paddingRight: 10,
            paddingLeft: 10,
            marginLeft: 8,
            fontSize: 13,
          }}
          onClick={() => this.props.onSelect(id)}
        >
          Select
        </button>
        {id !== 0 && (
          <div
            style={{
              padding: 5,
              paddingBottom: 2,
              backgroundColor: "white",
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={() => this.props.onPreview(id)}
          >
            <img
              src={require("../Assets/ic_preview.png")}
              alt=""
              style={{ width: 15 }}
            />
          </div>
          // <button
          //   style={{
          //     border: "none",
          //     borderRadius: 6,
          //     height: 25,
          //     backgroundColor: "white",
          //     color: "black",
          //     cursor: "pointer",
          //     outline: "none",
          //     paddingRight: 10,
          //     paddingLeft: 10,
          //     fontSize: 13,
          //   }}
          //   onClick={() => this.props.onPreview(id)}
          // >
          //   P
          // </button>
        )}
      </div>
    );
  }
}
