import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import { MAX_WIDTH } from "./Constants";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponentID: this.props.currentComponentID,
      data: this.props.data,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (
      this.state.currentComponentID === this.state.data.id ||
      this.state.currentComponentID === null
    ) {
      this.setState({
        data: nextProp.data,
      });
    }
  }

  getSectionHeight() {
    if (this.state.data.sectionHeight === "auto") {
      return "100%";
    } else if (this.state.data.sectionHeight === "extraSmall") {
      return 190;
    } else if (this.state.data.sectionHeight === "small") {
      return 270;
    } else if (this.state.data.sectionHeight === "medium") {
      return 450;
    } else if (this.state.data.sectionHeight === "large") {
      return 650;
    } else if (this.state.data.sectionHeight === "extraLarge") {
      return 750;
    }
    return 450;
  }

  render() {
    let paddingVertical = this.state.data.paddingVertical;
    let paddingHorizontal = this.state.data.paddingHorizontal;

    return (
      <div
        className={
          "ColumnDiv " +
          "PaddingHorizontal" +
          paddingHorizontal +
          " PaddingVertical" +
          paddingVertical
        }
        style={{
          backgroundColor: this.state.data.backgroundColor
            ? this.state.data.backgroundColor
            : "white",
          justifyContent: "center",
          position: "relative",
          height: this.getSectionHeight(),
          boxSizing: "border-box",
        }}
      >
        {ComponentManager.getWideScreenSectionBackground(
          this.state.data.backgroundColor
        )}
        <img
          src={
            this.state.data.image
              ? this.state.data.image
              : require("./Assets/ic_banner_default.png")
          }
          alt=""
          style={{
            width: "100%",
            height: this.getSectionHeight(),
            objectFit: "cover",
            position: "relative",
            opacity: this.state.data.image ? 1 : 0.45,
          }}
        />
        <div
          className="ColumnDiv"
          style={{
            boxSizing: "border-box",
            position: "absolute",
            alignItems: "center",
            left: 0,
            paddingLeft: "inherit",
            paddingRight: "inherit",
          }}
        >
          <text
            className={
              "BannnerText " +
              ComponentManager.getHeaderFontSize(this.state.data.textSize)
            }
            style={{
              boxSizing: "border-box",
              fontWeight: "bold",
              width: "90%",
              textAlign: this.state.data.textAlignment,
              color: this.state.data.textColor,
            }}
          >
            {this.state.data.heading}
          </text>
          <text
            className={
              "BannnerText " +
              ComponentManager.getTextFontSize(this.state.data.textSize)
            }
            style={{
              width: "90%",
              textAlign: this.state.data.textAlignment,
              color: this.state.data.textColor,
              whiteSpace: "pre-wrap",
            }}
          >
            {this.state.data.text}
          </text>
        </div>
      </div>
    );
  }
}
