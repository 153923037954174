export default class Template2 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835508043_Screenshot%202020-09-23%20at%2012.31.23%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Featured collection",
        backgroundColor: "#fafafa",
        heading: "Collection Title",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835084640_Screenshot%202020-09-23%20at%2012.22.26%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835092614_Screenshot%202020-09-23%20at%2012.22.34%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835097755_Screenshot%202020-09-23%20at%2012.22.39%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835106841_Screenshot%202020-09-23%20at%2012.22.45%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835113048_Screenshot%202020-09-23%20at%2012.22.56%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835119012_Screenshot%202020-09-23%20at%2012.23.01%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835123998_Screenshot%202020-09-23%20at%2012.23.05%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835128414_Screenshot%202020-09-23%20at%2012.23.10%20PM.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 7,
        paddingHorizontal: 6,
      },
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "small",
        textAlignment: "center",
        textSize: "medium",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600835508043_Screenshot%202020-09-23%20at%2012.31.23%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
    ];
  }
}
