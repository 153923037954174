import ApiConstants from "./ApiConstants";

export default class DomainManager {
  static didDomainCreated = false;
  static domainName = null;
  static presenter = null;

  static isDomainCreated() {
    return DomainManager.didDomainCreated;
  }

  static domainCreated(name) {
    DomainManager.domainName = name;
    DomainManager.didDomainCreated = true;
  }

  static getDomainURL() {
    var str_link =
      "https://" + DomainManager.domainName + ApiConstants.SUB_DOMAIN;
    return str_link;
  }
}
