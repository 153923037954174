import React, { Component } from "react";
import "./App.css";
import Banner from "./Banner";
import ImageAndTextSection from "./ImageAndTextSection";
import ImageSettings from "./ImageSettings";
import RowWithColumnsSection from "./RowWithColumnsSection";
import ComponentsHolderPanel from "./ComponentsHolderPanel";
import AppManager from "./AppManager";
import ComponentManager, { componentType } from "./ComponentManager";
import ImagePickerPopup from "./ImagePickerPopup";
import HeaderMenu from "./HeaderMenu";
import PageManager from "./PageManager";
import CreateDomainPopup from "./CreateDomainPopup";
import CreatePagePopup from "./CreatePagePopup";
import PageApiManager from "./PageApiManager";
import WebsiteManager from "./WebsiteManager";
import EditWebsitePopup from "./EditWebsitePopup";
import ButtonSection from "./ButtonSection";
import MenuManager from "./MenuManager";
import TemplateManager from "./Templates/TemplateManager";
import TemplateSelectionPopup from "./Templates/TemplateSelectionPopup";
import TemplatePreviewPopup from "./Templates/TemplatePreviewPopup";
import { MAX_WIDTH } from "./Constants";
import ProductsScreen from "./eCommerce/ProductsScreen";
import CartScreen from "./eCommerce/CartScreen";
import CheckoutScreen from "./eCommerce/CheckoutScreen";
import OrderConfirmationScreen from "./eCommerce/OrderConfirmationScreen";
import ApiManagerInitial from "./ApiManagerInitial";
import StoreManager from "./eCommerce/Managers/StoreManager";

export default class WebsiteBuilder extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.prevY = 0;
    this.isScrollingUp = false;
    this.isScrollingDown = false;
    this.scrollStartedY = 0;
    this.distanceScrolled = 0;

    this.state = {
      didAllPagesDataReceived: false,
      allPages: [],
      imagePopupSelectedImage: null,
      isImagePopupRequired: false,
      isCreateDomainPopupRequired: false,
      isCreatePagePopupRequired: false,
      isEditWebsitePopupRequired: false,
      isReadOnly: true,
      isUserLoggedIn: false,
      isComponentsHolderPanelRequired: true,
      isSettingsPanelRequired: false,
      backgroundColor: "#ffffff",
      isFullScreenActivated: false,
      currentComponentID: null,
      currentComponentIndex: null,
      currentComponentData: null,
      componentsData: [],
      websiteTitle: "My Website",
      websiteLogo: null,
      menuSettings: MenuManager.getMenuSettings(),
      isMenuRequired: true,
      isTemplatePopupRequired: false,
      isPreviewTemplatePopupRequired: false,
      previewTemplateData: [],
      isProductScreenRequired: false,
      isCartScreenRequired: false,
      isCheckoutScreenRequired: false,
      isOrderConfirmationScreenRequired: false,
    };
  }

  componentDidMount() {
    window.onbeforeunload = function (e) {
      if (!AppManager.isDataSaved()) {
        e.returnValue = "onbeforeunload";
        return "onbeforeunload";
      }
    };

    // this.checkIfPublicURL();

    let apiManagerInitial = new ApiManagerInitial();
    apiManagerInitial.callInitialApis(this);
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  allInitialApisCallsDone() {
    this.setState({
      isReadOnly: !AppManager.isUserLoggedIn(),
      isUserLoggedIn: AppManager.isUserLoggedIn(),
      menuSettings: MenuManager.getMenuSettings(),
    });

    let pagesData = PageManager.getAllPages();
    if (pagesData.length === 0) {
      this.setState({
        isTemplatePopupRequired: true,
      });
    } else {
      this.setFirstPageData();
    }
  }

  setFirstPageData() {
    let pagesData = PageManager.getAllPages();
    if (StoreManager.isStoreAvaialble()) {
      if (pagesData.length > 0) {
        pagesData.splice(1, 0, PageApiManager.getProductPageData());
      }
    }
    PageManager.setAllPages(pagesData);

    this.setState({
      allPages: PageManager.getAllPages(),
      isTemplatePopupRequired: false,
      isPreviewTemplatePopupRequired: false,
    });
    this.setCurrentPageDetails(0);

    this.setState({
      didAllPagesDataReceived: true,
      websiteTitle: WebsiteManager.getWebsiteTitle(),
      websiteLogo: WebsiteManager.getWebsiteLogo(),
    });
  }

  //First time if pages not abvailable. It will create new page and continue
  pageCreatedSuccess(pageID, pageName, jsonData) {
    PageManager.addPage(pageID, pageName, JSON.stringify(jsonData));
    this.setFirstPageData();
  }
  pageCreateFailed() {}

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  //Save current page data before we move to new page
  saveCurrentPageData(callbackRequired = false) {
    if (PageManager.getCurrentPageID() === 9999) {
      return;
    }

    PageApiManager.updatePage(
      this.state.componentsData,
      this,
      callbackRequired ? this.pageDataUpdated : null
    );
    PageManager.saveComponentsDataForPage(
      PageManager.getCurrentPageID(),
      this.state.componentsData
    );
  }

  pageDataUpdated = () => {
    alert("Page saved successfully.");
  };
  ////////////////////////////////////////////////////////////////

  setCurrentPageDetails(index) {
    PageManager.setCurrentPageIndex(index);
    let data = PageManager.getComponentsDataForPage(
      PageManager.getCurrentPageID()
    );

    this.setState({
      componentsData: data,
    });
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  componentSelected = (rowIndex) => {
    if (this.state.isReadOnly) {
      //DO NOTHING
      // } else if (this.state.isFullScreenActivated === true) {
      //   this.setState({
      //     isFullScreenActivated: false,
      //   });
    } else {
      this.setState({
        isFullScreenActivated: false,
        currentComponentIndex: rowIndex,
        currentComponentID: this.state.componentsData[rowIndex].id,
        currentComponentData: this.state.componentsData[rowIndex],
        isSettingsPanelRequired: true,
        isComponentsHolderPanelRequired: false,
      });
    }
  };

  componentDataChanged = (newData) => {
    var newComponentsData = this.state.componentsData;
    newComponentsData[this.state.currentComponentIndex] = newData;
    this.setState({
      componentsData: newComponentsData,
      imagePopupSelectedImage: null,
    });
    AppManager.dataNotSaved();
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  menuDataChanged = (menuData) => {
    this.setState({
      menuSettings: menuData,
    });
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  loginTapped = () => {
    this.gotoLoginPage();
  };
  gotoLoginPage() {
    AppManager.gotoLoginPage();
  }
  saveTapped = () => {
    this.saveCurrentPageData(true);
  };
  publishTapped = () => {
    this.saveCurrentPageData();
    this.showCreateDomainPopup();
  };
  fullscreenTapped = () => {
    this.setState({
      isReadOnly: !this.state.isFullScreenActivated,
      isFullScreenActivated: !this.state.isFullScreenActivated,
    });
  };
  backgroundColorChanged = (color) => {
    this.setState({
      backgroundColor: color,
    });
  };

  newPageTapped = () => {
    this.showCreatePagePopup();
  };
  pageButtonTapped = (index, title, id) => {
    if (id === 9999) {
      // this.props.history.push("/products");
      this.showProductScreen();
      // return;
    } else {
      this.resetAllStatesForProductsSection();
    }
    //Save current page data before we move to new page
    if (!this.state.isReadOnly) {
      this.saveCurrentPageData();
    }

    this.resetComponentSelection();

    this.setCurrentPageDetails(index);
    this.setState({
      pages: PageManager.getAllPages(),
    });
  };

  editWebsiteTapped = () => {
    this.resetComponentSelection();
    this.showEditWebsitePopup();
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  resetAllStatesForProductsSection() {
    this.setState({
      isProductScreenRequired: false,
      isCartScreenRequired: false,
      isCheckoutScreenRequired: false,
      isOrderConfirmationScreenRequired: false,
    });
  }

  showProductScreen = () => {
    this.resetAllStatesForProductsSection();
    this.setState({
      isProductScreenRequired: true,
    });
  };

  showCartScreen = () => {
    this.resetAllStatesForProductsSection();
    this.setState({
      isCartScreenRequired: true,
    });
  };

  showCheckoutScreen = () => {
    this.resetAllStatesForProductsSection();
    this.setState({
      isCheckoutScreenRequired: true,
    });
  };

  showOrderConfirmationScreen = () => {
    this.resetAllStatesForProductsSection();
    this.setState({
      isOrderConfirmationScreenRequired: true,
    });
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="App">
        {!this.state.isPreviewTemplatePopupRequired &&
          !this.state.isTemplatePopupRequired &&
          !this.state.didAllPagesDataReceived &&
          this.getLoadingView()}
        {this.state.isTemplatePopupRequired && this.getTemplateSelectionPopup()}
        {this.state.isPreviewTemplatePopupRequired &&
          this.getPreviewTemplatePopup()}
        {this.state.didAllPagesDataReceived && this.state.isMenuRequired && (
          <HeaderMenu
            menuSettings={this.state.menuSettings}
            websiteTitle={this.state.websiteTitle}
            websiteLogo={this.state.websiteLogo}
            pages={this.state.allPages}
            isReadOnly={this.state.isReadOnly}
            isUserLoggedIn={this.state.isUserLoggedIn}
            onBackgroundColorChanged={this.backgroundColorChanged}
            onFullscreenTapped={this.fullscreenTapped}
            onLoginTapped={this.loginTapped}
            onSaveTapped={this.saveTapped}
            onPublishTapped={this.publishTapped}
            onNewPageTapped={this.newPageTapped}
            onPageButtonTapped={this.pageButtonTapped}
            onEditTapped={this.editWebsiteTapped}
          />
        )}
        {this.state.didAllPagesDataReceived &&
          this.state.isProductScreenRequired && (
            <ProductsScreen onGotoCart={this.showCartScreen} />
          )}
        {this.state.didAllPagesDataReceived &&
          this.state.isCartScreenRequired && (
            <CartScreen onPlaceOrder={this.showCheckoutScreen} />
          )}
        {this.state.didAllPagesDataReceived &&
          this.state.isCheckoutScreenRequired && (
            <CheckoutScreen onCheckout={this.showOrderConfirmationScreen} />
          )}
        {this.state.didAllPagesDataReceived &&
          this.state.isOrderConfirmationScreenRequired && (
            <OrderConfirmationScreen
              onContinueShopping={this.showProductScreen}
            />
          )}
        {this.state.didAllPagesDataReceived &&
          !this.state.isProductScreenRequired &&
          !this.state.isCartScreenRequired &&
          !this.state.isCheckoutScreenRequired &&
          !this.state.isOrderConfirmationScreenRequired && (
            <div className="ColumnDiv" style={{ flex: 1, overflow: "hidden" }}>
              <div
                className="ColumnDiv"
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {!this.state.isReadOnly &&
                    !this.state.isFullScreenActivated &&
                    this.getLeftPanel()}
                  {this.getWebsiteTemplate()}
                </div>
              </div>
            </div>
          )}
        {(this.state.isImagePopupRequired ||
          this.state.isCreateDomainPopupRequired ||
          this.state.isCreatePagePopupRequired ||
          this.state.isEditWebsitePopupRequired) &&
          ComponentManager.getTransparentBackground()}
        {this.state.isCreateDomainPopupRequired && (
          <CreateDomainPopup
            onCancel={this.createDomainCancelTapped}
            onDomainCreate={this.domainCreated}
          />
        )}
        {this.state.isCreatePagePopupRequired && (
          <CreatePagePopup
            onCancel={this.createPageCancelTapped}
            onPageCreate={this.pageCreated}
          />
        )}
        {this.state.isEditWebsitePopupRequired && (
          <EditWebsitePopup
            menuSettings={this.state.menuSettings}
            onDataChanged={this.menuDataChanged}
            websiteLogo={this.state.imagePopupSelectedImage}
            onCancel={this.editWebsiteCancelTapped}
            onUpdate={this.websiteUpdated}
            onImageTapped={this.showImagePopup}
          />
        )}
        {this.state.isImagePopupRequired && (
          <ImagePickerPopup
            onImageSelect={this.imagePopupImageSelected}
            onCancel={this.imagePopupCancelTapped}
          ></ImagePickerPopup>
        )}
      </div>
    );
  }

  getLoadingView() {
    return (
      <div
        className="App-header"
        style={{ backgroundColor: "white", color: "black", fontSize: 14 }}
      >
        <text style={{ fontSize: 24, fontWeight: "bold" }}>
          Loading the pages!
        </text>
        <text style={{ color: "gray", marginTop: 8 }}>Please wait...</text>
      </div>
    );
  }
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  showCreateDomainPopup = () => {
    this.setState({
      isCreateDomainPopupRequired: true,
    });
  };
  hideCreateDomainPopup() {
    this.setState({
      isCreateDomainPopupRequired: false,
    });
  }

  domainCreated = () => {
    // this.hideCreateDomainPopup();
  };
  createDomainCancelTapped = () => {
    this.hideCreateDomainPopup();
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  showCreatePagePopup = () => {
    this.setState({
      isCreatePagePopupRequired: true,
    });
  };
  hideCreatePagePopup() {
    this.setState({
      isCreatePagePopupRequired: false,
    });
  }

  pageCreated = (pageID, pageTitle, templateData) => {
    // this.hideCreatePagePopup();
    this.resetAllStatesForProductsSection();
    this.setState({
      allPages: PageManager.getAllPages(),
      isSettingsPanelRequired: false,
      isComponentsHolderPanelRequired: true,
      isFullScreenActivated: false,
    });

    this.saveCurrentPageData();
    PageManager.saveComponentsDataForPage(pageID, templateData);
    this.setCurrentPageDetails(PageManager.getAllPages().length - 1);
  };
  createPageCancelTapped = () => {
    this.hideCreatePagePopup();
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  showEditWebsitePopup = () => {
    this.setState({
      isEditWebsitePopupRequired: true,
      imagePopupSelectedImage: this.state.menuSettings.logo,
    });
  };
  hideEditWebsitePopup() {
    this.setState({
      isEditWebsitePopupRequired: false,
    });
  }

  websiteUpdated = () => {
    this.hideEditWebsitePopup();
  };
  editWebsiteCancelTapped = () => {
    this.hideEditWebsitePopup();
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  showImagePopup = () => {
    this.setState({
      isImagePopupRequired: true,
    });
  };
  hideImagePopup() {
    this.setState({
      isImagePopupRequired: false,
    });
  }
  imagePopupImageSelected = (img) => {
    this.setState({
      imagePopupSelectedImage: img,
    });
    this.hideImagePopup();
  };
  imagePopupCancelTapped = () => {
    this.hideImagePopup();
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  deleteComponent = (index) => {
    this.state.componentsData.splice(index, 1);
    this.setState({});
    AppManager.dataNotSaved();
  };
  moveComponentUp = (index) => {
    let data = this.state.componentsData;
    let currentIndexData = data[index];
    let upIndexData = data[index - 1];

    data[index - 1] = currentIndexData;
    data[index] = upIndexData;

    this.setState({
      componentsData: data,
    });
    AppManager.dataNotSaved();
  };
  moveComponentDown = (index) => {
    let data = this.state.componentsData;
    let currentIndexData = data[index];
    let downIndexData = data[index + 1];

    data[index + 1] = currentIndexData;
    data[index] = downIndexData;

    this.setState({
      componentsData: data,
    });
    AppManager.dataNotSaved();
  };

  addNewComponent = (type) => {
    let component = ComponentManager.getNewComponent(
      this.getNewComponentID(),
      type
    );
    this.state.componentsData.push(component);
    this.setState({});
    AppManager.dataNotSaved();
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  getNewComponentID() {
    var id = 0;
    for (let i = 0; i < this.state.componentsData.length; i++) {
      if (this.state.componentsData[i].id > id) {
        id = this.state.componentsData[i].id;
      }
    }

    id++;
    return id;
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  resetComponentSelection() {
    this.setState({
      // imagePopupSelectedImage: null,
      isSettingsPanelRequired: false,
      isComponentsHolderPanelRequired: true,
      currentComponentID: null,
    });
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  getLeftPanel() {
    return (
      <div
        style={{
          width: 270,
          backgroundColor: "rgb(245, 245, 245)",
          padding: 16,
          overflowY: "scroll",
          position: "relative",
          border: "solid",
          borderWidth: 1,
          borderColor: "rgb(235, 235, 235)",
        }}
      >
        {this.state.isSettingsPanelRequired && (
          <div
            style={{
              position: "absolute",
              right: 18,
              fontSize: 12,
              top: 22,
            }}
          >
            {ComponentManager.getImageButton(
              require("./Assets/ic_close.png"),
              () => this.resetComponentSelection(),
              0,
              10
            )}
          </div>
        )}
        {this.state.isSettingsPanelRequired && (
          <ImageSettings
            data={this.state.currentComponentData}
            onComponentDataChanged={this.componentDataChanged}
            onImageTapped={this.showImagePopup}
            selectedImage={this.state.imagePopupSelectedImage}
          />
        )}
        {this.state.isComponentsHolderPanelRequired && (
          <ComponentsHolderPanel
            components={this.state.componentsData}
            onDelete={this.deleteComponent}
            onUp={this.moveComponentUp}
            onDown={this.moveComponentDown}
            onAddNewComponent={this.addNewComponent}
          />
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  elementScrollData = (value) => {
    //Scrolling UP
    if (this.myRef.current.scrollTop > this.prevY) {
      if (this.state.isMenuRequired) {
        if (this.isScrollingUp === false) {
          this.isScrollingUp = true;
          this.isScrollingDown = false;
          this.scrollStartedY = this.myRef.current.scrollTop;
        } else {
          //If scrolling UP already active
          if (this.myRef.current.scrollTop - this.scrollStartedY > 400) {
            this.setState({
              isMenuRequired: false,
            });
          }
        }
      }
    } else {
      //Scrolling DOWN
      if (!this.state.isMenuRequired) {
        if (this.isScrollingDown === false) {
          this.isScrollingDown = true;
          this.isScrollingUp = false;
          this.scrollStartedY = this.myRef.current.scrollTop;
        } else {
          //If scrolling DOWN already active
          if (this.scrollStartedY - this.myRef.current.scrollTop > 300) {
            this.setState({
              isMenuRequired: true,
            });
          }
        }
      }
    }

    this.prevY = this.myRef.current.scrollTop;
  };

  getWebsiteTemplate() {
    return (
      <div
        ref={this.myRef}
        style={{
          display: "flex",
          flex: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          justifyContent: "center",
          backgroundColor: "white", //rgb(245, 245, 245)
          position: "relative",
        }}
        onScroll={
          this.state.menuSettings && this.state.menuSettings.autoHideMenu
            ? this.elementScrollData
            : null
        }
      >
        <div
          style={{
            width: this.getComponentHolderWidth(),
          }}
        >
          {this.renderAllComponents()}
        </div>
      </div>
    );
  }

  getComponentHolderWidth() {
    let maxWidth = MAX_WIDTH;
    if (this.state.isReadOnly && window.screen.width > maxWidth) {
      return maxWidth;
    }
    return "100%";
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  renderAllComponents() {
    var arr = [];
    for (let i = 0; i < this.state.componentsData.length; i++) {
      let data = this.state.componentsData[i];
      if (data.type === componentType.BUTTON_SECTION) {
        arr.push(
          <div
            id={data.id}
            style={{
              cursor: this.state.isReadOnly ? "default" : "pointer",
              width: "100%",
            }}
            onClick={() => this.componentSelected(i)}
          >
            <ButtonSection
              data={data}
              currentComponentID={this.state.currentComponentID}
              isReadOnly={this.state.isReadOnly}
            />
          </div>
        );
      } else if (data.type === componentType.BANNER) {
        arr.push(
          <div
            id={data.id}
            style={{
              cursor: this.state.isReadOnly ? "default" : "pointer",
              width: "100%",
            }}
            onClick={() => this.componentSelected(i)}
          >
            <Banner
              data={data}
              currentComponentID={this.state.currentComponentID}
            />
          </div>
        );
      } else if (
        data.type === componentType.IMAGE_WITH_TEXT ||
        data.type === componentType.PARAGRAPH ||
        data.type === componentType.NEWS_LETTER
      ) {
        arr.push(
          <div
            id={data.id}
            style={{
              cursor: this.state.isReadOnly ? "default" : "pointer",
              width: "100%",
            }}
            onClick={() => this.componentSelected(i)}
          >
            <ImageAndTextSection
              data={data}
              currentComponentID={this.state.currentComponentID}
            />
          </div>
        );
      } else {
        if (
          data.type === componentType.ROW_WITH_COLUMNS ||
          data.type === componentType.COLLECTION ||
          data.type === componentType.TESTIMONIALS ||
          data.type === componentType.BLOG ||
          data.type === componentType.PHOTO_GALLERY
        ) {
          arr.push(
            <div
              id={data.id}
              style={{
                cursor: this.state.isReadOnly ? "default" : "pointer",
                width: "100%",
              }}
              onClick={() => this.componentSelected(i)}
            >
              <RowWithColumnsSection
                data={data}
                currentComponentID={this.state.currentComponentID}
                isReadOnly={this.state.isReadOnly}
              />
            </div>
          );
        }
      }
    }
    return arr;
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  getTemplateSelectionPopup() {
    return (
      <TemplateSelectionPopup
        isHidden={this.state.isPreviewTemplatePopupRequired}
        onPreview={this.showPreviewOfTemplate}
        onSelect={this.templateSelected}
      />
    );
  }

  showPreviewOfTemplate = (templateId) => {
    this.setState({
      previewTemplateData: TemplateManager.getTemplateData(templateId),
      isPreviewTemplatePopupRequired: true,
    });
  };

  templateSelected = (templateId) => {
    // alert(templateId);
    PageApiManager.createPage(
      "Home",
      TemplateManager.getTemplateData(templateId),
      this
    );
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  getPreviewTemplatePopup() {
    return (
      <TemplatePreviewPopup
        data={this.state.previewTemplateData}
        onClose={this.previewTemplatePopupClosed}
      />
    );
  }
  previewTemplatePopupClosed = () => {
    this.setState({
      isPreviewTemplatePopupRequired: false,
    });
  };

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
}
