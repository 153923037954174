import React, { Component } from "react";
import StoreManager from "./Managers/StoreManager";
import CheckoutApiClient from "./API/CheckoutApiClient";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { COLOR_BLUE } from "../Constants";

export default function StripeForm(props) {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {},
      },
      invalid: {
        color: "#FFC7EE",
      },
    },
    hidePostalCode: true,
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    if (!props.checkIfFormValid()) {
      return;
    }

    if (
      !StoreManager.isStripeAccountSetup() ||
      StoreManager.getStripeAccountID() === null
    ) {
      alert(
        "Sorry, we can not proceed with payment. It seems like merchant/seller has not yet setup his/her Stripe account."
      );
      props.onPaymentFail();
      return;
    }

    CheckoutApiClient.getStripeIntent(
      {
        amount: parseFloat(props.totalAmount),
        currency: StoreManager.getStoreCurrency().toLowerCase(),
      },
      (secret) => {
        callStripePayment(secret);
      },
      () => {
        //Intent response failed
        props.onPaymentFail();
      }
    );
  };

  const callStripePayment = async (secret) => {
    //seti_1Hk2MYBw7dCWTcPc3tRU7ifd_secret_IKhm4BwrCJOWG8ExqTJwWZpvALHmzlV
    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city: props.checkOutInfo.address.city,
            country: props.checkOutInfo.address.countryCode,
            line1: props.checkOutInfo.address.address,
            line2: "",
            postal_code: props.checkOutInfo.address.zip,
            state: props.checkOutInfo.address.state,
          },
          email: props.checkOutInfo.buyerEmail,
          name: props.checkOutInfo.buyerName,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      alert(result.error.message);
      props.onPaymentFail();
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        props.onPaymentSuccess();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      } else {
        props.onPaymentFail();
        alert("Payment failed due to some internal error.");
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        className="border-s border-radius-s shadow-s"
        style={{ padding: 15, width: "100%", boxSizing: "border-box" }}
      >
        <CardElement
          id="CardElement"
          options={CARD_ELEMENT_OPTIONS}
        ></CardElement>
      </div>
      <button
        className="parent-width border-radius-m cursor-hand fw-m"
        type="submit"
        style={{
          padding: 15,
          backgroundColor: COLOR_BLUE,
          color: "white",
          boxSizing: "border-box",
          border: "none",
          marginTop: 20,
          marginBottom: 100,
        }}
        onClick={handleSubmit}
      >
        Pay now
      </button>
    </div>
  );

  //     // We don't want to let default form submission happen here,
  //     // which would refresh the page.
  //     event.preventDefault();
}
