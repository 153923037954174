import ApiConstants from "./ApiConstants";
import ApiManager from "./ApiManager";
import ImageManager from "./ImageManager";

export default class FileServiceManager {
  ////////////////////////////////////////////
  ////////////////////////////////////////////
  static getFileApiRequestOptions(method, body = null) {
    var requestOptions = {
      method: method,
      mode: "cors",
      headers: {
        "Content-Type": "image/jpeg",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (method === "POST" || method === "PUT") {
      requestOptions = { ...requestOptions, body };
    }
    return requestOptions;
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getUploadFileURL(fileName, presenter) {
    if (ApiConstants.TENANT_ID === null) {
      presenter.fileUploadUrlFailed();
      alert("No tenant ID available");
      return;
    }

    let url =
      ApiConstants.URL.BASE +
      ApiConstants.URL.FILE_UPLOAD +
      "?relativeFilePath=" +
      ApiConstants.FILE_UPLOAD_RELATIVE_PATH +
      +ApiConstants.TENANT_ID +
      "/" +
      new Date().getTime() +
      "_" +
      fileName +
      "&bucketName=" +
      ApiConstants.FILE_UPLOAD_BUCKETNAME;

    fetch(url, ApiManager.getApiRequestOptions("POST", {}))
      .then((response) => response.text())
      .then((data) => {
        presenter.fileUploadUrlReceived(data);
      })
      .catch((error) => {
        presenter.fileUploadUrlFailed();
        alert(error);
      });
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static uploadFile(url, file, presenter) {
    fetch(url, FileServiceManager.getFileApiRequestOptions("PUT", file))
      .then((response) => response.text())
      .then((data) => {
        let index = url.indexOf("?");
        let imageURL = url.slice(0, index);
        presenter.fileUploadedSuccess(imageURL);
      })
      .catch((error) => {
        presenter.fileUploadFailed();
        alert(error);
      });
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getAllUploadedImages() {
    let url =
      ApiConstants.URL.BASE +
      ApiConstants.URL.ALL_UPLOADED_IMAGES +
      "?bucketName=" +
      ApiConstants.FILE_UPLOAD_BUCKETNAME +
      "&relativeFilePath=" +
      ApiConstants.FILE_UPLOAD_RELATIVE_PATH +
      ApiConstants.TENANT_ID;

    fetch(url, ApiManager.getApiRequestOptions("GET"))
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify(data));
        let arr = data;
        var arr_urls = arr.map(function (obj) {
          return obj.filePathUrl;
        });
        ImageManager.setImages(arr_urls);
      })
      .catch((error) => {
        console.log(error);
      });

    // let images = [
    //   "https://i.ibb.co/C1K75jK/1-Letty.png",
    //   "https://i.ibb.co/gmtcthW/11-Brian-O-Connor.png",
    //   "https://i.ibb.co/51M4yZH/111-Dominic-Toretto.png",
    //   "https://i.ibb.co/1GB08LZ/1111-Gisele.png",
    // ];
    // ImageManager.setImages(images);
  }
}
