export default class ApiConstants {
  static URL = {
    BASE_CONSTANT: process.env.REACT_APP_BASE_URL,
    BASE: process.env.REACT_APP_BASE_URL,
    LOGIN_REDIRECT: process.env.REACT_APP_LOGIN_URL,

    LOGIN_STATUS: "iam/auth/sign-in/login/status",

    FILE_UPLOAD: "file/upload",
    ALL_UPLOADED_IMAGES: "file/upload/list",

    CREATE_DOMAIN: "crm/guide/helpcenter",
    DOMAIN_NAME_CHECK: "crm/guide/validate-subdomain",

    WEBSITE_CRUD_API: "crm/guide/helpcenter",
    WEBSITE_PAGE_CRUD_API: "crm/website/page",

    MENU_CRUD_API: "crm/website/menu",

    PRODUCT_CRUD_API: "anonymous/commerce/product",
    CHECKOUT_API: "anonymous/commerce/checkout",
    STORE_API: "anonymous/commerce/store",
    STRIPE_INTENT: "anonymous/commerce/payment-intent",

    PUBLIC_URL: "anonymous/crm/guide/tenant-by-domain",
  };

  static FILE_UPLOAD_BUCKETNAME = process.env.REACT_APP_FILE_UPLOAD_BUCKETNAME;
  static FILE_UPLOAD_RELATIVE_PATH = process.env.REACT_APP_FILE_UPLOAD_PATH;
  static TENANT_ID = null;
  static TENANT_NAME = null;
  static ACCESS_TOKEN = null;

  static BUILDER_URL = process.env.REACT_APP_BUILDER_URL;
  static SUB_DOMAIN = process.env.REACT_APP_SUB_DOMAIN;

  static STORE_DASHBOARD_URL = process.env.REACT_APP_STORE_DASHBOARD_URL;

  static LOCALHOST_URL = "localhost:30000";

  static setPublicURLS(baseURL) {
    ApiConstants.URL.BASE = baseURL + ApiConstants.SUB_DOMAIN + "/wb/";
    ApiConstants.URL.WEBSITE_CRUD_API = "api/guide/helpcenter";
    ApiConstants.URL.WEBSITE_PAGE_CRUD_API = "api/website/page";
    ApiConstants.URL.MENU_CRUD_API = "api/website/menu";
  }
}
