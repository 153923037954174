import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import DomainManager from "./DomainManager";
import ApiManager from "./ApiManager";
import { COLOR_BLUE } from "./Constants";
import WebsiteApiManager from "./WebsiteApiManager";
import WebsiteManager from "./WebsiteManager";
import ApiConstants from "./ApiConstants";
import DKSpaceH from "./eCommerce/Components/DKSpaceH";

export default class CreateDomainPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Create domain",
      subTitle:
        "In order to publish your page, you need to create a domain for it. We will help you to setup your first domain.",
      createButtonTitle: "Create my domain",
      domainName: "",
      apiCalled: false,
      waitMsg: "Please wait while we create your domain....",
      domainCreated: false,
      domainURL: "",
    };
  }

  domainNameChanged = (e) => {
    if (!this.state.apiCalled) {
      this.setState({
        domainName: e.target.value,
      });
    }
  };

  componentDidMount() {
    if (DomainManager.isDomainCreated()) {
      this.setDataIfDomainCreated(false);
    }
  }

  render() {
    return (
      <div
        className="WindowPopup"
        style={{
          color: "black",
          paddingLeft: 30,
          paddingRight: 30,
          width: "70%",
          height: "auto",
        }}
      >
        <div style={{ position: "absolute", right: 15, top: 8 }}>
          {ComponentManager.getButton("X", "gray", this.cancelTapped)}
        </div>
        <div style={{ height: 5 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            opacity: 1,
          }}
        >
          <div
            style={{
              width: "65%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              fontSize: 16,
            }}
          >
            <text style={{ fontSize: 25, fontWeight: "bold" }}>
              {this.state.title}
            </text>
            <br />
            <text>{this.state.subTitle}</text>
            <br />
            {!this.state.domainCreated && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "85%",
                  marginTop: 15,
                  marginBottom: 20,
                }}
              >
                <text>https:// </text>
                <input
                  className="TextField"
                  style={{ marginBottom: 0, marginLeft: 5, marginRight: 5 }}
                  placeholder="your domain name"
                  type="text"
                  value={this.state.domainName}
                  onChange={this.domainNameChanged}
                />
                <text>{ApiConstants.SUB_DOMAIN}</text>
              </div>
            )}
            {this.state.domainCreated && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                  marginBottom: 25,
                }}
              >
                <a href={this.state.domainURL}>{this.state.domainURL}</a>
              </div>
            )}
            <div style={{ height: 10 }} />
            <text style={{ fontSize: 12, color: "gray" }}>
              • This will be your website url/link which you will be able to
              share with your friends, business partners, colleagues, teams and
              family.
            </text>
            <text style={{ fontSize: 12, color: "gray", marginTop: 5 }}>
              • Once your domain is up and running, you can create and publish
              multiple pages on your website.
            </text>
            <br />
          </div>

          <div
            style={{
              width: "35%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={require("./Assets/ic_createDomain.png")}
              alt=""
              style={{ width: "90%" }}
            />
          </div>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          {!this.state.apiCalled &&
            ComponentManager.getButton(
              this.state.createButtonTitle,
              COLOR_BLUE,
              this.createDomainTapped
            )}
          <DKSpaceH value={10} />
          {!this.state.apiCalled &&
            ComponentManager.getButton("Cancel", "tomato", this.cancelTapped)}
        </div>
        {this.state.apiCalled && (
          <text style={{ fontSize: 13, color: COLOR_BLUE, fontWeight: "700" }}>
            {this.state.waitMsg}
          </text>
        )}
        <div style={{ height: 15 }} />
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onCancel();
  };

  createDomainTapped = () => {
    if (this.state.domainName === "") {
      alert("Please enter domain name to proceed!");
      return;
    }

    if (this.isValidDomainName(this.state.domainName)) {
      this.setState({
        apiCalled: true,
      });

      let title = WebsiteManager.getWebsiteTitle();
      let logo = WebsiteManager.getWebsiteLogo()
        ? WebsiteManager.getWebsiteLogo()
        : "";
      let url = WebsiteManager.getWebsiteURL();
      let subDomain = this.state.domainName;
      WebsiteApiManager.updateWebsite(
        title,
        logo,
        url,
        subDomain.toLowerCase(),
        this
      );
    } else {
      let commonMsg =
        "Subdomain must consist of alphanumeric words starting with alphabet. Should not have any special characters and space between words.";
      alert(commonMsg);
    }
  };

  isValidDomainName(domainName) {
    var format1 = /^[a-z]/;
    var format2 = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    return format1.test(domainName) && !format2.test(domainName);
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  //Update is create, To create domain you need to update website sunbdomain detail
  websiteUpdatedSuccess(websiteDetails) {
    WebsiteManager.setWebsiteDetails(websiteDetails);
    DomainManager.domainCreated(websiteDetails.subdomain);
    this.setDataIfDomainCreated();
    this.props.onDomainCreate();
  }
  websiteUpdateFailed() {
    this.setState({
      apiCalled: false,
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  setDataIfDomainCreated(calledOnCreate = true) {
    var msg = calledOnCreate
      ? "Your domain has been created successfully. Now you can publish it to make it available to end-users. Your domain url is:"
      : "Your domain has been created. It's available to end-users. Below is your domain url:";
    var str_link = DomainManager.getDomainURL();
    this.setState({
      apiCalled: true,
      waitMsg: calledOnCreate
        ? "Domain created!"
        : "Domain has already created!",
      title: calledOnCreate ? "Congratulations!" : "Website is published!",
      subTitle: msg,
      domainCreated: true,
      domainURL: str_link,
    });
  }
}
