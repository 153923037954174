import React, { Component } from "react";
import TemplateManager from "./TemplateManager";
import { COLOR_BLUE, MAX_WIDTH, POPUP_WIDTH } from "../Constants";
import { componentType } from "../ComponentManager";
import ButtonSection from "../ButtonSection";
import Banner from "../Banner";
import ImageAndTextSection from "../ImageAndTextSection";
import RowWithColumnsSection from "../RowWithColumnsSection";

export default class TemplatePreviewPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentsData: this.props.data,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      componentsData: nextProp.data,
    });
  }

  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////

  render() {
    let widthOfPopup = POPUP_WIDTH + 3;
    let heightOfPopup = "100%";

    let maxWidth = MAX_WIDTH;
    if (window.screen.width > maxWidth) {
      widthOfPopup = widthOfPopup / 1.2;
    }

    widthOfPopup = widthOfPopup + "%";

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          className="WindowPopup"
          style={{
            borderRadius: 0,
            width: widthOfPopup,
            height: heightOfPopup,
            backgroundColor: "white",
            padding: 0,
          }}
        >
          {this.renderAllComponents()}
        </div>
        {this.getCloseButton()}
      </div>
    );
  }

  renderAllComponents() {
    var arr = [];
    for (let i = 0; i < this.state.componentsData.length; i++) {
      let data = this.state.componentsData[i];
      if (data.type === componentType.BUTTON_SECTION) {
        arr.push(
          <div
            id={data.id}
            style={{
              width: "100%",
            }}
          >
            <ButtonSection data={data} isReadOnly={false} />
          </div>
        );
      } else if (data.type === componentType.BANNER) {
        arr.push(
          <div
            id={data.id}
            style={{
              width: "100%",
            }}
          >
            <Banner data={data} />
          </div>
        );
      } else if (
        data.type === componentType.IMAGE_WITH_TEXT ||
        data.type === componentType.PARAGRAPH ||
        data.type === componentType.NEWS_LETTER
      ) {
        arr.push(
          <div
            id={data.id}
            style={{
              width: "100%",
            }}
          >
            <ImageAndTextSection data={data} />
          </div>
        );
      } else {
        if (
          data.type === componentType.ROW_WITH_COLUMNS ||
          data.type === componentType.COLLECTION ||
          data.type === componentType.TESTIMONIALS ||
          data.type === componentType.BLOG ||
          data.type === componentType.PHOTO_GALLERY
        ) {
          arr.push(
            <div
              id={data.id}
              style={{
                width: "100%",
              }}
            >
              <RowWithColumnsSection data={data} isReadOnly={false} />
            </div>
          );
        }
      }
    }
    return arr;
  }

  getCloseButton() {
    return (
      <button
        style={{
          border: "none",
          borderRadius: 6,
          height: 25,
          backgroundColor: "black",
          color: "white",
          marginLeft: 10,
          cursor: "pointer",
          outline: "none",
          position: "absolute",
          top: 20,
          right: "10%",
          zIndex: 9999,
        }}
        onClick={() => this.props.onClose()}
      >
        Close
      </button>
    );
  }
}
