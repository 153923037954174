import ApiConstants from "./ApiConstants";
import AppManager from "./AppManager";
import StoreApiClient from "./eCommerce/API/StoreApiClient";

export default class MenuManager {
  static menuSettings = null;

  static getDefaultMenuSettings() {
    return {
      logo: null,
      title: "My Website",
      layoutType: "1",
      menuHeight: "medium",
      backgroundColor: "#ffffff",
      textColor: "#000000",
      selectedTextColor: "#3057c4",
      titleColor: "#000000",
      selectedMenuButtonType: "2",
      shadowRequired: true,
      autoHideMenu: false,
      tenantId: ApiConstants.TENANT_ID,
    };
  }

  static getSettingsCopy(settings) {
    return {
      logo: settings.logo,
      title: settings.title,
      layoutType: settings.layoutType,
      menuHeight: settings.menuHeight,
      backgroundColor: settings.backgroundColor,
      textColor: settings.textColor,
      selectedTextColor: settings.selectedTextColor,
      titleColor: settings.titleColor,
      selectedMenuButtonType: settings.selectedMenuButtonType,
      shadowRequired: settings.shadowRequired,
      autoHideMenu: settings.autoHideMenu,
      tenantId: ApiConstants.TENANT_ID,
    };
  }

  static getMenuSettings() {
    if (MenuManager.menuSettings === null) {
      return MenuManager.getDefaultMenuSettings();
    } else {
      return MenuManager.menuSettings;
    }
  }

  static setMenuSettings(settings) {
    if (!AppManager.isUserLoggedIn()) {
      ApiConstants.TENANT_ID = settings.tenantId;
    }
    MenuManager.menuSettings = settings;
  }
}
