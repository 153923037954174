import Template0 from "./Template0";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";

export default class TemplateManager {
  static getAllTemplates() {
    return [
      {
        id: 0,
        thumbnailImage: require("../Assets/Templates/ic_template_0.png"),
      },
      {
        id: 1,
        thumbnailImage: require("../Assets/Templates/ic_template_1.png"),
      },
      {
        id: 2,
        thumbnailImage: require("../Assets/Templates/ic_template_2.png"),
      },
      {
        id: 3,
        thumbnailImage: require("../Assets/Templates/ic_template_3.png"),
      },
      {
        id: 4,
        thumbnailImage: require("../Assets/Templates/ic_template_4.png"),
      },
      {
        id: 5,
        thumbnailImage: require("../Assets/Templates/ic_template_5.png"),
      },
      {
        id: 6,
        thumbnailImage: require("../Assets/Templates/ic_template_6.png"),
      },
      {
        id: 7,
        thumbnailImage: require("../Assets/Templates/ic_template_7.png"),
      },
      {
        id: 8,
        thumbnailImage: require("../Assets/Templates/ic_template_8.png"),
      },
      {
        id: 9,
        thumbnailImage: require("../Assets/Templates/ic_template_9.png"),
      },
    ];
  }

  static getTemplateData(templateId) {
    switch (templateId) {
      case 0:
        return Template0.getData();
      case 1:
        return Template1.getData();
      case 2:
        return Template2.getData();
      case 3:
        return Template3.getData();
      case 4:
        return Template4.getData();
      case 5:
        return Template5.getData();
      case 6:
        return Template6.getData();
      case 7:
        return Template7.getData();
      case 8:
        return Template8.getData();
      case 9:
        return Template9.getData();
      default:
        return [];
    }
  }
}
