import React, { Component } from "react";
import "./App.css";
import ImageManager from "./ImageManager";
import ComponentManager from "./ComponentManager";
import FileServiceManager from "./FileServiceManager";
import { COLOR_BLUE } from "./Constants";

export default class ImagePickerPopup extends Component {
  fileToUpload = null;

  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      images: ImageManager.getAllUploadedImages(),
      selectedImageIndex: null,
      selectedImage: null,
    };
  }

  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////

  handleSelectedFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      //   let filePath = URL.createObjectURL(file);

      var idxDot = file.name.lastIndexOf(".") + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        this.fileToUpload = file;
        this.setState({ uploading: true });
        FileServiceManager.getUploadFileURL(file.name, this);
      } else {
        alert("Only jpg, jpeg, png files are allowed!");
      }
    }
  };

  ////////////////////////////////////////////////

  fileUploadUrlReceived(urlToUploadFile) {
    FileServiceManager.uploadFile(urlToUploadFile, this.fileToUpload, this);
  }
  fileUploadUrlFailed() {
    this.fileUploadDone();
  }

  ////////////////////////////////////////////////

  fileUploadedSuccess(url) {
    ImageManager.addNewImage(url);
    this.setState({
      images: ImageManager.getAllUploadedImages(),
    });
    this.fileUploadDone();
  }

  fileUploadFailed() {
    this.fileUploadDone();
  }

  ////////////////////////////////////////////////

  //Success or Fail
  fileUploadDone() {
    this.setState({
      uploading: false,
    });
  }

  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////

  imageSelected = (img, index, isDoubleTap) => {
    this.setState(
      {
        selectedImageIndex: index,
        selectedImage: img,
      },
      () => {
        if (isDoubleTap) {
          this.selectButtonTapped();
        }
      }
    );
  };

  selectButtonTapped = () => {
    if (this.state.selectedImage === null) {
      alert("Please select image to proceed");
      return;
    }
    this.props.onImageSelect(this.state.selectedImage);
  };
  cancelButtonTapped = () => {
    this.props.onCancel();
  };

  ////////////////////////////////////////////////
  ////////////////////////////////////////////////

  render() {
    return (
      <div
        className="WindowPopup"
        style={{ color: "black", paddingLeft: 30, paddingRight: 30 }}
      >
        <div style={{ position: "absolute", right: 15, top: 8 }}>
          {ComponentManager.getButton("X", "gray", this.cancelButtonTapped)}
        </div>
        {this.getHeader()}
        {this.getSubHeader()}
        {this.getImagesHolder()}
        {this.getFooter()}
      </div>
    );
  }

  getHeader() {
    return (
      <div
        style={{
          height: 30,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <text style={{ fontSize: 16, fontWeight: "400" }}>Add Images</text>
      </div>
    );
  }

  ////////////////////////////////////////////////
  ////////////////////////////////////////////////

  getSubHeader() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: 30,
        }}
      >
        <text style={{ fontSize: 14, fontWeight: "bold" }}>Upload</text>
        <div
          style={{
            height: 3,
            backgroundColor: COLOR_BLUE,
            width: "100%",
            marginTop: 10,
          }}
        />
        <div style={{ marginTop: 10 }}>
          {this.state.uploading && (
            <text style={{ fontSize: 14, color: "red" }}>
              File uploading...
            </text>
          )}
          {!this.state.uploading && (
            <input
              type="file"
              accept="image/jpg,image/jpeg,image/png"
              onChange={this.handleSelectedFile}
            />
          )}
        </div>
        <text style={{ fontSize: 12, marginTop: 10, color: "gray" }}>
          You may upload one file at once. Use .png, .jpg, .jpeg formats
        </text>
      </div>
    );
  }

  getFooter() {
    return (
      <div
        style={{
          height: 50,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
          bottom: 15,
          left: 18,
          paddingTop: 5,
          backgroundColor: "white",
        }}
      >
        {ComponentManager.getButton(
          "Select",
          COLOR_BLUE,
          this.selectButtonTapped
        )}
        {ComponentManager.getButton("Cancel", "gray", this.cancelButtonTapped)}
      </div>
    );
  }

  getImagesHolder() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 20,
          height: "67%",
          overflowY: "scroll",
          alignContent: "flex-start",
        }}
      >
        {this.getAllImages()}
      </div>
    );
  }
  getAllImages() {
    var arr = [];
    for (let i = this.state.images.length - 1; i >= 0; i--) {
      arr.push(this.getImage(this.state.images[i], i));
    }
    return arr;
  }

  getImage(url, index) {
    return (
      <div
        style={{
          width: index === this.state.selectedImageIndex ? 120 : 130,
          height: index === this.state.selectedImageIndex ? 120 : 130,
          border: "solid",
          borderWidth: index === this.state.selectedImageIndex ? 5 : 0.5,
          borderColor:
            index === this.state.selectedImageIndex
              ? COLOR_BLUE
              : "rgb(240, 240, 240)",
          marginRight: 15,
          marginBottom: 15,
        }}
        onClick={() => this.imageSelected(url, index, false)}
        onDoubleClick={() => this.imageSelected(url, index, true)}
      >
        <img
          src={url}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }
}
