export default class Template4 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837939691_Screenshot%202020-09-23%20at%201.11.01%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "Column header",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837943456_Screenshot%202020-09-23%20at%201.11.08%20PM.png",
            heading: "Add a title or tagline",
            text:
              "Share blog posts, products, or promotions with your customers. Use this text to describe products, share details on availability and style, or as a space to display recent reviews or FAQs.",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837946271_Screenshot%202020-09-23%20at%201.11.13%20PM.png",
            heading: "Add a title or tagline",
            text:
              "Share blog posts, products, or promotions with your customers. Use this text to describe products, share details on availability and style, or as a space to display recent reviews or FAQs.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "2",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 3,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Photo Gallery",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837946271_Screenshot%202020-09-23%20at%201.11.13%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837943456_Screenshot%202020-09-23%20at%201.11.08%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837946271_Screenshot%202020-09-23%20at%201.11.13%20PM.png",
            heading: "",
            text: "",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "small",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-common-env.s3.ap-southeast-1.amazonaws.com/dev/website-builder/11581/1600837939691_Screenshot%202020-09-23%20at%201.11.01%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
    ];
  }
}
