import React, { Component } from "react";
import "../Common.css";
import "../App.css";
import HeaderMenu from "../HeaderMenu";
import MenuManager from "../MenuManager";
import CartManager from "./Managers/CartManager";
import { COLOR_BLUE } from "../Constants";
import ProductRow from "./ProductRow";
import PriceDetails from "./PriceDetails";

export default class CartScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      totalAmount: 0,
    };

    setTimeout(() => {
      this.resetData();
    }, 10);
  }

  render() {
    return (
      <div
        className="column parent-height"
        style={{ backgroundColor: "white" }}
      >
        {/* <HeaderMenu
          menuSettings={MenuManager.getMenuSettings()}
          websiteTitle="Deskera"
          //   websiteLogo={WebsiteManager.getWebsiteLogo()}
          pages={[]}
          isReadOnly={false}
          isUserLoggedIn={this.state.isUserLoggedIn}
          onBackgroundColorChanged={this.backgroundColorChanged}
          onFullscreenTapped={this.fullscreenTapped}
          onLoginTapped={this.loginTapped}
          onSaveTapped={this.saveTapped}
          onPublishTapped={this.publishTapped}
          onNewPageTapped={this.newPageTapped}
          onPageButtonTapped={this.pageButtonTapped}
          onEditTapped={this.editWebsiteTapped}
        /> */}
        <div
          className="column parent-width"
          style={{
            padding: "3vw",
            maxWidth: 1200,
            alignSelf: "center",
            flex: 1,
            overflowY: "hidden",
          }}
        >
          <text className=" fw-b" style={{ fontSize: 22 }}>
            {"My Shopping Bag (" + this.state.products.length + " items)"}
          </text>
          <div
            className="column parent-width"
            style={{
              marginTop: 20,
              flex: 1,
              overflowY: "hidden",
            }}
          >
            <div className="row parent-height">
              <div
                className="column parent-height"
                style={{
                  flex: 1,
                  overflowY: "scroll",
                }}
              >
                {this.getProductsView(this.state.products)}
                <div className="column parent-width priceDetailsBottomPopup">
                  {this.getPriceDetails()}
                </div>
                {this.state.products.length === 0 && this.getEmptyView()}
              </div>
              <div className="column parent-height border-radius-s priceDetailsPopup">
                {this.getPriceDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetData() {
    this.setState({
      products: CartManager.getProducts(),
      totalAmount: CartManager.getTotalAmount(),
    });
  }

  getProductsView(products) {
    return (
      <div
        className="row parent-width"
        style={{ flexWrap: "wrap", marginTop: 5 }}
      >
        {this.getAllProducts(products)}
      </div>
    );
  }

  getAllProducts(products) {
    let arr = [];
    for (let i = 0; i < products.length; i++) {
      arr.push(this.getProduct(i, products[i]));
    }
    return arr;
  }
  getProduct(index, data) {
    return (
      <div
        className="column parent-width"
        style={{
          marginBottom: "2%",
        }}
      >
        <ProductRow
          index={index}
          data={data}
          onDelete={this.productDeleted}
          onQuantityMinus={this.productQuantityDecreased}
          onQuantityPlus={this.productQuantityIncreased}
        />
      </div>
    );
  }

  productDeleted = (index, data) => {
    CartManager.deleteProduct(index);
    this.resetData();
  };
  productQuantityDecreased = (index, data) => {
    if (data["quantity"] === 1) {
      return;
    }
    data["quantity"] = data["quantity"] - 1;
    CartManager.updateProduct(index, data);
    this.resetData();
  };
  productQuantityIncreased = (index, data) => {
    data["quantity"] = data["quantity"] + 1;
    CartManager.updateProduct(index, data);
    this.resetData();
  };
  placeOrder = () => {
    if (this.state.products.length === 0) {
      alert("Please add items to your cart and proceed.");
    } else {
      // this.props.history.push("/checkout");
      this.props.onPlaceOrder();
    }
  };

  getPriceDetails() {
    return (
      <PriceDetails
        totalAmount={this.state.totalAmount}
        buttonTitle="Place Order"
        callback={this.placeOrder}
      />
    );
  }

  getEmptyView() {
    return (
      <div className="column parent-size justify-content-center align-items-center">
        <img
          src={require("../Assets/ic_emptyBag.png")}
          alt=""
          style={{ width: 300, marginTop: -70 }}
        />
      </div>
    );
  }
}
