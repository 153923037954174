import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WebsiteBuilder from "./WebsiteBuilder";
import ProductScreen from "./eCommerce/ProductsScreen";
import CartScreen from "./eCommerce/CartScreen";
import CheckoutScreen from "./eCommerce/CheckoutScreen";
import OrderConfirmationScreen from "./eCommerce/OrderConfirmationScreen";

export default class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={WebsiteBuilder} />
            <Route exact path="/wb" component={WebsiteBuilder} />
            <Route path="/products" component={ProductScreen} />
            <Route path="/cart" component={CartScreen} />
            <Route path="/checkout" component={CheckoutScreen} />
            <Route path="/order" component={OrderConfirmationScreen} />
          </Switch>
        </Router>
      </div>
    );
  }
}
