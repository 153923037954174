import ApiConstants from "./ApiConstants";
import ApiManager from "./ApiManager";
import WebsiteManager from "./WebsiteManager";

export default class WebsiteApiManager {
  static getWebsiteDetails(presenter) {
    fetch(
      ApiConstants.URL.BASE +
        ApiConstants.URL.WEBSITE_CRUD_API +
        "?website_type=builder",
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        if ((data.Errors && data.Errors.length > 0) || data.code) {
          presenter.websiteDataReceived(null);
        } else {
          presenter.websiteDataReceived(data);
        }
      })
      .catch((error) => {
        alert("Internal server error occured. Try again later.");
        presenter.websiteDataReceived(null);
      });
  }

  static createWebsite(name, url, presenter) {
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.WEBSITE_CRUD_API,
      ApiManager.getApiRequestOptions("POST", {
        title: name,
        url: url,
        // subdomain: url,
        website_type: "builder",
      })
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          let websiteData = {
            title: name,
            url: url,
            id: data.Id,
            logo: "",
            subdomain: "",
            website_type: "builder",
          };

          presenter.websiteCreated(websiteData);
        } else {
          alert(JSON.stringify(data));
        }
      })
      .catch((error) => {
        ApiManager.checkIfSessionExpired(error, true);
      });
  }

  static updateWebsite(name, logo, url, domain, presenter) {
    let dataToUpdate = {
      id: WebsiteManager.getWebsiteID(),
      title: name,
      logo_image: logo,
      favicon_image: logo,
      hero_image: logo,
      // url: url,
      is_active: true,
    };

    //To update/create domain, if it not to update domain, do not add subdomain to data
    if (domain !== "" && WebsiteManager.getWebsiteDomain() !== domain) {
      dataToUpdate = { ...dataToUpdate, subdomain: domain.toLowerCase() };
    }

    let apiURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.WEBSITE_CRUD_API +
      "?website_type=builder";

    fetch(apiURL, ApiManager.getApiRequestOptions("PUT", dataToUpdate))
      .then((response) => response.json())
      .then((data) => {
        if ((data.Errors && data.Errors.length > 0) || data.code) {
          alert(data.errorMessage);
          presenter.websiteUpdateFailed();
        } else {
          //If it is not to update domain then we need to add domain details to data
          if (WebsiteManager.getWebsiteDomain() === domain) {
            dataToUpdate = { ...dataToUpdate, subdomain: domain };
          }
          presenter.websiteUpdatedSuccess(dataToUpdate);
        }
      })
      .catch((error) => {
        ApiManager.checkIfSessionExpired(error, true);
        presenter.websiteUpdateFailed();
      });
  }
}
