import React, { Component } from "react";
// import Components from "../Components";

// PROPS
// - icon
// - title
// - onClick
// - backgroundColor
// - color
// - isReverse

export default class DKButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: this.props.onClick,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      onClick: nextProps.onClick,
    });
  }
  render() {
    return (
      <div
        className={
          (this.props.isReverse ? "row-reverse" : "row") +
          " border-radius-m " +
          this.props.className
        }
        style={{
          width: "auto",
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 7,
          paddingBottom: 7,
          backgroundColor: this.props.backgroundColor,
          color: this.props.color,
          fontSize: 13,
          cursor: "pointer",
        }}
        onClick={this.state.onClick ? this.state.onClick : null}
      >
        {/* {this.props.icon && Components.getIcon(this.props.icon, 15)} */}
        {this.props.icon && (
          <div style={{ width: this.props.isReverse ? 5 : 10 }} />
        )}
        <text className="text-align-left">{this.props.title}</text>
      </div>
    );
  }
}
